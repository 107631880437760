import React, { Component } from 'react';
import './GalleryGrid.scss';
import 'photoswipe/dist/photoswipe.css'
import { Gallery, Item } from 'react-photoswipe-gallery'

import Bike from '../../assets/Gallery/bike.jpg'
import BikeThumbnail from '../../assets/Gallery/bike_thumbnail.jpg'

import Panda from '../../assets/Gallery/panda.jpg'
import PandaThumbnail from '../../assets/Gallery/panda_thumbnail.jpg'

import Water from '../../assets/Gallery/water.jpg'
import WaterThumbnail from '../../assets/Gallery/water_thumbnail.jpg'

import Windmill from '../../assets/Gallery/windmill.jpg'
import WindmillThumbnail from '../../assets/Gallery/windmill_thumbnail.jpg'

import Deer from '../../assets/Gallery/deer.jpg'
import DeerThumbnail from '../../assets/Gallery/deer_thumbnail.jpg'

import Cliffs from '../../assets/Gallery/cliffs.jpg'
import CliffsThumbnail from '../../assets/Gallery/cliffs_thumbnail.jpg'

const MyGallery = () => (
  <Gallery shareButton={false}>
    <Item
      original={Cliffs}
      thumbnail={CliffsThumbnail}
      width="2006"
      height="1498"
      title="Neist Point Lighthouse on the Isle of Skye"
    >
      {({ ref, open }) => (
        <img className="gallery-grid__thumbnail" ref={ref} onClick={open} src={CliffsThumbnail} alt="Neist Point Lighthouse on the Isle of Skye"/>
      )}
    </Item>
    <Item
      original={Panda}
      thumbnail={PandaThumbnail}
      width="2006"
      height="1498"
      title="Red panda in Highland Wildlife Park"
    >
      {({ ref, open }) => (
        <img className="gallery-grid__thumbnail" ref={ref} onClick={open} src={PandaThumbnail} alt="Red panda in Highland Wildlife Park"/>
      )}
    </Item>
    <Item
      original={Water}
      thumbnail={WaterThumbnail}
      width="2006"
      height="1498"
      title="Sea foam on the Isle of Skye"
    >
      {({ ref, open }) => (
        <img className="gallery-grid__thumbnail" ref={ref} onClick={open} src={WaterThumbnail} alt="Sea foam on the Isle of Skye"/>
      )}
    </Item>
    <Item
      original={Bike}
      thumbnail={BikeThumbnail}
      width="2006"
      height="1498"
      title="Professional cyclist in Rutland–Melton CiCLE Classic"
    >
      {({ ref, open }) => (
        <img className="gallery-grid__thumbnail" ref={ref} onClick={open} src={BikeThumbnail} alt="Fast moving professional cyclist in Rutland–Melton CiCLE Classic"/>
      )}
    </Item>
    <Item
      original={Windmill}
      thumbnail={WindmillThumbnail}
      width="2006"
      height="1498"
      title="Chesterton windmill + comet NEOWISE"
    >
      {({ ref, open }) => (
        <img className="gallery-grid__thumbnail" ref={ref} onClick={open} src={WindmillThumbnail} alt="Chesterton windmill with stars and comet NEOWISE in background"/>
      )}
    </Item>
    <Item
      original={Deer}
      thumbnail={DeerThumbnail}
      width="2006"
      height="1498"
      title="Scottish Highlands deer"
    >
      {({ ref, open }) => (
        <img className="gallery-grid__thumbnail" ref={ref} onClick={open} src={DeerThumbnail} alt="Deer in Scottish Highlands"/>
      )}
    </Item>
  </Gallery>
)

export class GalleryGrid extends Component {

  render() {
    return(
      <div className="gallery-grid">
        <MyGallery />
      </div>
    )
  }
}

export default GalleryGrid;