import React, { Component } from 'react';
import './Footer.scss';
import CV_URL from '../../variables.js';

export class Footer extends Component {

    render() {
        return (
            <div className="footer__container">
                <div className="page-contents footer">
                    <div>
                        <h3 className="footer__heading">Theo Matthews</h3>
                        <div className="footer__contact">
                            <a className="footer__icon" href="https://www.linkedin.com/in/theomatthews/" target="_blank" rel="noreferrer">
                                <svg alt="Linkedin icon" className="footer__linkedin" width="16" height="16" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
                                    <g>
                                        <path d="M15.9963 16V15.9993H16.0003V10.1313C16.0003 7.26065 15.3823 5.04932 12.0263 5.04932C10.413 5.04932 9.33034 5.93465 8.88834 6.77398H8.84167V5.31732H5.65967V15.9993H8.973V10.71C8.973 9.31732 9.237 7.97065 10.9617 7.97065C12.661 7.97065 12.6863 9.55998 12.6863 10.7993V16H15.9963Z" />
                                        <path d="M0.26416 5.31799H3.58149V16H0.26416V5.31799Z" />
                                        <path d="M1.92133 0C0.860667 0 0 0.860667 0 1.92133C0 2.982 0.860667 3.86067 1.92133 3.86067C2.982 3.86067 3.84267 2.982 3.84267 1.92133C3.842 0.860667 2.98133 0 1.92133 0V0Z" />
                                    </g>
                                </svg>
                            </a>
                            <a className="footer__icon" href="mailto:contact@theomatthews.co.uk" target="_blank" rel="noreferrer">
                                <span className="footer__email material-icons">email</span>
                            </a>
                        </div>
                        <div className="footer__nav-links--mobile">
                            <a className="footer__nav-link" href="/">
                                Home
                            </a>
                            <a className="footer__nav-link" href="/#projects">
                                Projects
                            </a>
                            <a className="footer__nav-link" href="/#about_me">
                                About me
                            </a>
                            <a className="footer__nav-link" href="/#experience">
                                Experience
                            </a>
                            <a className="footer__nav-link footer__cv-link" href={CV_URL} target="_blank" rel="noreferrer" download="cv.pdf">
                                <span className="footer__cv-link-text">CV</span>
                                <span className="material-icons footer__cv-link-icon icon__launch"></span>
                            </a>
                        </div>
                        <p className="footer__text">Website created by Theo using
                            <a className="footer__link" href="https://reactjs.org/" target="_blank" rel="noreferrer">React</a>,
                            <a className="footer__link" href="https://sass-lang.com/" target="_blank" rel="noreferrer">Sass</a>,
                            <a className="footer__link" href="https://aws.amazon.com/amplify/" target="_blank" rel="noreferrer">AWS</a> and
                            <a className="footer__link" href="https://fonts.google.com/" target="_blank" rel="noreferrer">Google Fonts</a>.
                        </p>
                        <p className="footer__text">Copyright &copy; 2022 Theo Matthews. All Rights Reserved.</p>
                    </div>
                    <div className="footer__nav-links--desktop">
                        <a className="footer__nav-link" href="/">
                            Home
                        </a>
                        <a className="footer__nav-link" href="/#projects">
                            Projects
                        </a>
                        <a className="footer__nav-link" href="/#about_me">
                            About me
                        </a>
                        <a className="footer__nav-link" href="/#experience">
                            Experience
                        </a>
                        <a className="footer__nav-link footer__cv-link" href={CV_URL} target="_blank" rel="noreferrer" download="cv.pdf">
                            <span className="footer__cv-link-text">CV</span>
                            <span className="material-icons footer__cv-link-icon icon__launch"></span>
                        </a>
                    </div>
                </div>
            </div>
        )
    }
}

export default Footer;