import React, { Component } from 'react';
import './AboutMe.scss';
import ProfilePic from '../../assets/profilepic.png'
import GalleryGrid from '../GalleryGrid/GalleryGrid'
import Border from '../../assets/border.svg'

export class AboutMe extends Component {

  render() {
    return(
      <div className="about-me__container">
        <img className="jagged-border--top" src={Border} alt="border"></img>
        <div className="jagged-border__inner-container">
          <div className="about-me page-contents">
            <div className="about-me__anchor" id="about_me"></div>
            <h1 className="about-me__heading gradient-text">About me</h1>
            <div className="about-me__profile">
              <div className="about-me__paragraphs">
                <p>I am a detail-loving Product Designer who finds few things more satisfying than designing solutions that meet real people’s needs. Through a design-thinking, iterative process, I’ve led the creation of multiple enterprise apps in highly technical domains, such as finance and pharmaceuticals. Aided by my BSc in Computer Science, I like to take a holistic view and work across the entire design process, feeling extremely comfortable operating in cross-functional teams.</p>
                <p>Outside of work, I really enjoy photography as a creative outlet. I like endurance sports and have completed multiple marathons and long distance cycles, raising thousands for charity in the process. The rest of my spare time is spent following tech/design news, reading, and keeping up with friends &#38; family.</p>
              </div>
              <img className="about-me__profile-pic" src={ProfilePic} alt="It's a me, Theo"></img>
            </div>
            <h4 className="about-me__photography-heading sea-green-text">Photography</h4>
            <GalleryGrid />
          </div>
        </div>
        <img className="jagged-border--bottom" src={Border} alt="border"></img>
      </div>
    )
  }
}

export default AboutMe;