import React, { Component } from 'react';
import '../Project.scss';
import Border from '../../../assets/border.svg'
import BackToTop from '../../BackToTop/BackToTop';
import 'photoswipe/dist/photoswipe.css'
import { Gallery, Item } from 'react-photoswipe-gallery'
import HeroPic from '../../../assets/projects/pharma/pharma-laptop.png'
import PharmaFirst_Home_Thumbnail from '../../../assets/projects/pharma/pharma-first__home--thumbnail.jpg'
import PharmaFirst_Home from '../../../assets/projects/pharma/pharma-first__home.jpg'
import PharmaFirst_CompoundNav_Thumbnail from '../../../assets/projects/pharma/pharma-first__compound-nav--thumbnail.jpg'
import PharmaFirst_CompoundNav from '../../../assets/projects/pharma/pharma-first__compound-nav.jpg'
import PharmaSecond_Switcher_Thumbnail from '../../../assets/projects/pharma/pharma-second__switcher--thumbnail.jpg'
import PharmaSecond_Switcher from '../../../assets/projects/pharma/pharma-second__switcher.jpg'
import PharmaSecond_Survey_Thumbnail from '../../../assets/projects/pharma/pharma__survey--thumbnail.jpg'
import PharmaSecond_Survey from '../../../assets/projects/pharma/pharma__survey.jpg'
import PharmaFirst_TrialLink_Thumbnail from '../../../assets/projects/pharma/pharma-first__trial-links--thumbnail.jpg'
import PharmaFirst_TrialLink from '../../../assets/projects/pharma/pharma-first__trial-links.jpg'
import PharmaFirst_TrialsFilter_Thumbnail from '../../../assets/projects/pharma/pharma-first__trials-filter--thumbnail.jpg'
import PharmaFirst_TrialsFitler from '../../../assets/projects/pharma/pharma-first__trials-filter.jpg'
import PharmaSecond_Search_Thumbnail from '../../../assets/projects/pharma/pharma-second__search--thumbnail.jpg'
import PharmaSecond_Search from '../../../assets/projects/pharma/pharma-second__search.jpg'
import PharmaSecond_Homepage_Thumbnail from '../../../assets/projects/pharma/pharma-second__homepage--thumbnail.jpg'
import PharmaSecond_Homepage from '../../../assets/projects/pharma/pharma-second__homepage.jpg'
import PharmaThird_Homepage_Thumbnail from '../../../assets/projects/pharma/pharma-third__homepage--thumbnail.jpg'
import PharmaThird_Homepage from '../../../assets/projects/pharma/pharma-third__homepage.jpg'
import Pharma_Brand_Thumbnail from '../../../assets/projects/pharma/pharma__brand--thumbnail.jpg'
import Pharma_Brand from '../../../assets/projects/pharma/pharma__brand.jpg'
import PharmaGallery from './PharmaGallery/PharmaGallery';
import ProjFundCard from '../ProjFund/ProjFundCard'
import ProjPaymentsCard from '../ProjPayments/ProjPaymentsCard'

const Gallery1 = () => (
  <Gallery shareButton={false}>
    <Item
      original={PharmaFirst_Home}
      thumbnail={PharmaFirst_Home_Thumbnail}
      width="1431"
      height="807"
      title="First iteration of the homepage"
    >
      {({ ref, open }) => (
        <div className="proj__gallery--2-thumbnail-container">
          <img className="proj__gallery--2-thumbnail" ref={ref} onClick={open} src={PharmaFirst_Home_Thumbnail} alt="First iteration of the homepage" />
          <span className="proj__gallery-caption">First iteration homepage</span>
        </div>
      )}
    </Item>
    <Item
      original={PharmaFirst_CompoundNav}
      thumbnail={PharmaFirst_CompoundNav_Thumbnail}
      width="1431"
      height="807"
      title="First iteration of the investigational compounds view"
    >
      {({ ref, open }) => (
        <div className="proj__gallery--2-thumbnail-container" >
          <img className="proj__gallery--2-thumbnail" ref={ref} onClick={open} src={PharmaFirst_CompoundNav_Thumbnail} alt="First iteration of the investigational compounds view" />
          <span className="proj__gallery-caption">Organised around cancer conditions</span>
        </div>
      )}
    </Item>
  </Gallery>
)

const Gallery2 = () => (
  <Gallery shareButton={false}>
    <Item
      original={PharmaSecond_Switcher}
      thumbnail={PharmaSecond_Switcher_Thumbnail}
      width="1400"
      height="788"
      title="Second iteration of the compounds page with improved navigation"
    >
      {({ ref, open }) => (
        <div className="proj__gallery--1-thumbnail-container">
          <img className="proj__gallery--1-thumbnail" ref={ref} onClick={open} src={PharmaSecond_Switcher_Thumbnail} alt="Second iteration of the compounds page with improved navigation" />
          <span className="proj__gallery-caption">Switchers to improve app navigation</span>
        </div>
      )}
    </Item>
  </Gallery>
)

const Gallery3 = () => (
  <Gallery shareButton={false}>
    <Item
      original={PharmaSecond_Survey}
      thumbnail={PharmaSecond_Survey_Thumbnail}
      width="1185"
      height="545"
      title="Results from Google Forms survey"
    >
      {({ ref, open }) => (
        <div className="proj__gallery--1-thumbnail-container">
          <img className="proj__gallery--1-thumbnail" ref={ref} onClick={open} src={PharmaSecond_Survey_Thumbnail} alt="Results from Google Forms survey" />
        </div>
      )}
    </Item>
  </Gallery>
)

const Gallery4 = () => (
  <Gallery shareButton={false}>
    <Item
      original={PharmaFirst_TrialLink}
      thumbnail={PharmaFirst_TrialLink_Thumbnail}
      width="1431"
      height="807"
      title="First iteration of the compound page with links to clinical trials"
    >
      {({ ref, open }) => (
        <div className="proj__gallery--2-thumbnail-container">
          <img className="proj__gallery--2-thumbnail" ref={ref} onClick={open} src={PharmaFirst_TrialLink_Thumbnail} alt="First iteration of the compound page with links to clinical trials" />
          <span className="proj__gallery-caption">Relevant trial links</span>
        </div>
      )}
    </Item>
    <Item
      original={PharmaFirst_TrialsFitler}
      thumbnail={PharmaFirst_TrialsFilter_Thumbnail}
      width="1431"
      height="807"
      title="First iteration of clinical trials view with filters"
    >
      {({ ref, open }) => (
        <div className="proj__gallery--2-thumbnail-container" >
          <img className="proj__gallery--2-thumbnail" ref={ref} onClick={open} src={PharmaFirst_TrialsFilter_Thumbnail} alt="First iteration of clinical trials view with filters" />
          <span className="proj__gallery-caption">Filters for trials</span>
        </div>
      )}
    </Item>
  </Gallery>
)

const Gallery5 = () => (
  <Gallery shareButton={false}>
    <Item
      original={PharmaSecond_Search}
      thumbnail={PharmaSecond_Search_Thumbnail}
      width="1233"
      height="713"
      title="Search function to access trials faster"
    >
      {({ ref, open }) => (
        <div className="proj__gallery--1-thumbnail-container">
          <img className="proj__gallery--1-thumbnail" ref={ref} onClick={open} src={PharmaSecond_Search_Thumbnail} alt="Search function to access trials faster" />
        </div>
      )}
    </Item>
  </Gallery>
)

const Gallery6 = () => (
  <Gallery shareButton={false}>
    <Item
      original={PharmaSecond_Homepage}
      thumbnail={PharmaSecond_Homepage_Thumbnail}
      width="1233"
      height="690"
      title="Second iteration homepage styled with new branding"
    >
      {({ ref, open }) => (
        <div className="proj__gallery--2-thumbnail-container">
          <img className="proj__gallery--2-thumbnail" ref={ref} onClick={open} src={PharmaSecond_Homepage_Thumbnail} alt="Second iteration homepage styled with new branding" />
          <span className="proj__gallery-caption">Second iteration homescreen</span>
        </div>
      )}
    </Item>
    <Item
      original={PharmaThird_Homepage}
      thumbnail={PharmaThird_Homepage_Thumbnail}
      width="1431"
      height="807"
      title="Third iteration homepage styled with new branding"
    >
      {({ ref, open }) => (
        <div className="proj__gallery--2-thumbnail-container" >
          <img className="proj__gallery--2-thumbnail" ref={ref} onClick={open} src={PharmaThird_Homepage_Thumbnail} alt="Third iteration homepage styled with new branding" />
          <span className="proj__gallery-caption">Third iteration homescreen</span>
        </div>
      )}
    </Item>
  </Gallery>
)

const Gallery7 = () => (
  <Gallery shareButton={false}>
    <Item
      original={Pharma_Brand}
      thumbnail={Pharma_Brand_Thumbnail}
      width="868"
      height="1194"
      title="Example brand guidelines provided by client"
    >
      {({ ref, open }) => (
        <div className="proj__gallery--1-thumbnail-container">
          <img className="proj__gallery--1-thumbnail" ref={ref} onClick={open} src={Pharma_Brand_Thumbnail} alt="Example brand guidelines provided by client" />
          <span className="proj__gallery-caption">Example page from brand guidelines</span>
        </div>
      )}
    </Item>
  </Gallery>
)

export class ProjPharma extends Component {

  render() {
    return (
      <div className="proj__container">
        <div className="page-contents">
          <span className="proj__breadcrumb"><a className="link-text" href="/">Home</a> / <a className="link-text" href="/#projects">Projects</a> / </span>
          <h1 className="gradient-text">Selling the latest pharmaceutical research to medics.</h1>

          <div>
            <div className="proj__meta">
              <span className="proj__meta-icon material-icons">person</span>
              <span className="proj__meta-text">User researcher,  interaction + visual designer, basic front-end developer</span>
            </div>

            <div className="proj__meta">
              <span className="proj__meta-icon material-icons">groups</span>
              <span className="proj__meta-text">2 UX designers, 1 full stack developer, product owner &#38; project manager</span>
            </div>

            <div className="proj__meta">
              <span className="proj__meta-icon material-icons">watch_later</span>
              <span className="proj__meta-text">10 weeks</span>
            </div>
          </div>

          <div className="proj__intro">
            <div>
              <p>A large pharmaceutical client wanted to help their representatives, known as Medical Science Liaisons (MSLs), at conferences better showcase the company’s latest research, and drive sales with medical professionals. Along with this, they wanted to increase recruitment for the hundreds of medical trials they ran around the world.</p>
              <p>The client had created an app that would be setup on large touchscreens for MSLs and medics to use at conferences, but had identified some problems. MSLs struggled to find the information they needed, meaning they couldn’t as easily meet their goals. Medics passing by the booth found it difficult to navigate, quickly giving up, taking away a negative impression of the company. </p>
              <p>We were therefore tasked with redesigning the app, transforming it into a first-class software experience that would help MSLs do their jobs and act as a great showpiece to medics.</p>
            </div>

            <img className="proj__hero-pic" src={HeroPic} alt="Laptop with application running on it"></img>
          </div>

          <div className="proj__strategy">
            <h3 className="proj__heading-3 sea-green-text">Strategy</h3>
            <p>To fix the identified issues and discover any others that might exist within the app, I helped devise the following plan:</p>
            <ul className="bullet-list proj__bullet-list">
              <li><span className="bullet-list__text">Conduct user survey;</span></li>
              <li><span className="bullet-list__text">Domain + user research to help form information architecture (IA);</span></li>
              <li><span className="bullet-list__text">Design and develop first iteration of app;</span></li>
              <li><span className="bullet-list__text">Incorporate feedback from real world user testing;</span></li>
              <li><span className="bullet-list__text">Tech lead determines tech stack, I develop all the markup and responsive SCSS styling.</span></li>
            </ul>
            <p>The client had rejected our request to involve users directly in the design process, so to get user involvement and validate our assumptions, we chose to do a survey and create a first iteration of the app, from which feedback could be gathered.</p>
          </div>

          <div className="proj__solutions">
            <h3 className="proj__heading-3 sea-green-text">Solutions</h3>
            <p>In the following sections, I will detail three interesting issues we identified, how we found them and what we did to solve them.</p>
            <div className="table-card__overflow-container--1">
              <div className="table-card__overflow-container--2">
                <div className="table-card__container">
                  <table className="table-card">
                    <thead>
                      <tr>
                        <th className="table-card__heading"><h4 className="sea-green-text">Problem</h4></th>
                        <th className="table-card__heading"><h4 className="sea-green-text">Solution</h4></th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td className="table-card__data table-card__numbered-cell">
                          <h5 className="sea-green-text">1.</h5>
                          <p className="table-card__text">Poorly designed <strong>information architecture and navigation</strong> makes life difficult for all users</p>
                        </td>
                        <td className="table-card__data">
                          <p className="table-card__text">Domain research and surveys guided our redesign of the IA and nav, aligning it with the user’s mental model</p>
                        </td>
                      </tr>

                      <tr>
                        <td className="table-card__data table-card__numbered-cell">
                          <h5 className="sea-green-text">2.</h5>
                          <p className="table-card__text">Existing app didn’t sufficiently support MSLs with their trial <strong>recruitment</strong> goals</p>
                        </td>
                        <td className="table-card__data">
                          <p className="table-card__text">Create clinical trials section with filters, convenient links to trials in relevant sections and a new search function</p></td>
                      </tr>

                      <tr>
                        <td className="table-card__data table-card__numbered-cell">
                          <h5 className="sea-green-text">3.</h5>
                          <p className="table-card__text">Initial <strong>visual design</strong> didn’t align with new guidelines and had usability problems</p>
                        </td>
                        <td className="table-card__data">
                          <p className="table-card__text">Create new visual design based on guidelines and use contrast checker for legibility</p>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>

        <img className="jagged-border--top" src={Border} alt="border"></img>
        <div className="jagged-border__inner-container">
          <div className="page-contents proj__problem">
            <h2 className="proj__problem-heading gradient-text">Problem 1.</h2>
            <h4 className="proj__problem-sub-heading sea-green-text">Information architecture and navigation</h4>
            <p>Fundamental to any app is its information architecture (IA) and navigation design. Through the survey and our own usage, we understood these were both sub-par in the existing app.</p>
            <p>I used Google Forms to create the surveys, and one question I asked was what topics MSLs liked to cover when talking with medics. This, along with analysing other existing websites and apps that covered similar topics, allowed us to create an IA that was optimised for the user's mental model and the content. One of the key changes we made was designing access to the ‘Investigational Compounds’ (drugs to you and I) around cancer conditions. With the research, we believed this would be an ideal solution for MSLs and medics as cancer conditions would be a language both user types understood.</p>
            <p>Below is the first iteration of the homepage and navigation for the investigational compounds:</p>
            <div className="proj__gallery">
              <Gallery1 />
            </div>
            <p>After letting MSLs use the app for a few months, we learned that our principal assumption of organising the information around cancer conditions was correct, but there was a problem. Users were struggling to switch between the two most used sections of the app. To fix this, I added a prominent switch for the two sections. I also added a new dropdown in the header that allowed for better global navigation, enabling fast transition between all sections of the app without having to go to the homepage.</p>
            <div className="proj__gallery">
              <Gallery2 />
            </div>
          </div>
        </div>
        <img className="jagged-border--bottom" src={Border} alt="border"></img>

        <div className="page-contents proj__problem">
          <h2 className="proj__problem-heading gradient-text">Problem 2.</h2>
          <h4 className="proj__problem-sub-heading sea-green-text">Recruitment</h4>
          <p>We were told at the beginning of the project that when MSLs talk to medics, recruiting for trials was a big priority. Through the survey data, we confirmed this:</p>
          <div className="proj__gallery">
            <Gallery3 />
          </div>
          <p>Improving the recruitment sections of the app was partially taken care of by redesigning the IA and navigation. To further improve it though, I added direct links to relevant trials in each compound’s homepage. Basic trial information like its recruiting status or the trial phase were also included on these links to help MSLs pick the right one. Along with this, I enhanced the dedicated clinical trials section of the app by adding filters. These filters would allow MSLs to zero in on the trials they were most interested in.</p>
          <div className="proj__gallery">
            <Gallery4 />
          </div>
          <p>The main piece of feedback we received after the first iteration of the app was that the MSLs wanted a search function. They wanted to simply enter in a trial’s ID, and be taken straight to it. Instead of just creating a search for trial IDs, I felt we could easily create a global search that would work across the entire app. For the design, I was inspired by the MacOS Spotlight. I like how seamless Spotlight is. It keeps you in your current context and results appear as soon as you start typing. It also organises the results into sections, which I thought would be very useful as we’d need to return multiple types of result.</p>
          <p>Below is my implemented design. Access to the search is always available in the global nav, and the results are neatly organised into sections, with icons used to help delineate. Results appear as you type: </p>
          <div className="proj__gallery">
            <Gallery5 />
          </div>
        </div>

        <img className="jagged-border--top" src={Border} alt="border"></img>
        <div className="jagged-border__inner-container">
          <div className="page-contents proj__problem">
            <h2 className="proj__problem-heading gradient-text">Problem 3.</h2>
            <h4 className="proj__problem-sub-heading sea-green-text">Visual design &#38; branding</h4>
            <p>The guidance we received from the client for the visual design was to emulate the style of an existing website. We devised a dark theme as we thought it gave it quite a cool look, and would make a positive impression. As it turned out though, on the show floors, its usability wasn’t so great due in part to the bright lights used at the conferences.</p>
            <p>For the second iteration, I would solve this usability issue and also consider the client’s request to align the app with their latest branding. This was challenging as the provided brand guidelines lacked many software specific examples of how to apply the visual treatment. Understanding the “dos and don’ts”, plus extracting the relationships of fonts from their print media examples, helped me form a visual design system and create an app that looked like it was produced internally by the client. To ensure text legibility, the contrast was regularly checked, maintaining WCAG AAA levels throughout.</p>
            <div className="proj__gallery">
              <Gallery6 />
            </div>
            <div className="proj__gallery">
              <Gallery7 />
            </div>
          </div>
        </div>
        <img className="jagged-border--bottom" src={Border} alt="border"></img>

        <div className="page-contents proj__problem">
          <h2 className="proj__problem-heading proj__conclusion gradient-text">Conclusion</h2>
          <p>MSLs have been delighted that they can guide medics through an app that consolidates all of the latest research and trial information into a single, user-centred resource. The client has also been extremely pleased with the work produced over the two phases, particularly appreciating its latest rebranding and interaction enhancements. The app has been used in many conferences across the globe, and with more use, we expect more feedback and feature requests from users, influencing how it’s improved in the years to come.</p>
        </div>
        <PharmaGallery />
        <div className="more-projects">
          <div className="page-contents">
            <h3 className="more-projects__heading gradient-text">More projects</h3>
          </div>
          <div className="project-card__horizontal-scroll-container">
            <div className="project-card__horizontal-scroll">
              <ProjFundCard />
              <ProjPaymentsCard />
            </div>
          </div>
        </div>
        <BackToTop />
      </div>
    )
  }
}

export default ProjPharma;