import React, { Component } from 'react';
import './HomeHero.scss';
import { Link } from "react-scroll";
import CV_URL from '../../variables.js';

export class HomeHero extends Component {

  render() {
    return(
      <div className="home-hero" id="top-home-section">
          <div className="page-contents">
            <div className="home-hero__headings">
                <h6 className="home-hero__heading-6">I'm detailed</h6>
                <h5 className="home-hero__heading-5">I'm holistic</h5>
                <h4 className="home-hero__heading-4">I'm technical</h4>
                <h3 className="home-hero__heading-3">I'm empathetic</h3>
                <h2 className="home-hero__heading-2">I'm a UX designer</h2>
                <h1 className="home-hero__heading-1">I'm Theo Matthews.</h1>
            </div>
            <div>
                <Link href="#" className="home-hero__link" smooth={true} duration={400} to="projects">
                    <span className="link-text">Projects</span>
                    <span className="material-icons home-hero__link-icon icon__arrow_forward"></span>
                </Link>
                <Link href="#" className="home-hero__link" smooth={true} duration={500} to="about_me">
                    <span className="link-text">About me</span>
                    <span className="material-icons home-hero__link-icon icon__arrow_forward"></span>
                </Link>
                <Link href="#" className="home-hero__link" smooth={true} duration={500} to="experience">
                    <span className="link-text">Experience</span>
                    <span className="material-icons home-hero__link-icon icon__arrow_forward"></span>
                </Link>
                <a className="home-hero__link" href={CV_URL} target="_blank"  rel="noreferrer" download="cv.pdf">
                    <span className="link-text">CV</span>
                    <span className="material-icons home-hero__link-icon icon__launch"></span>
                </a>
            </div>
          </div>
          <p id="jump_to_me"></p>
      </div>
    )
  }
}

export default HomeHero;