import React, { Component } from "react";
import classnames from "classnames";
import "./NavBar.scss";
import { Link } from "react-scroll";
import CV_URL from '../../variables.js';

export default class Navbar extends Component {
  constructor(props) {
    super(props);

    this.state = {
      navBG: false,
      pageURL: window.location.pathname,
      homepage: false
    };
  }

  isHomepage = () => { 
    var homepage = this.state;
    var pageURL = window.location.pathname;
    var navBG = this.state.navBG;
    
    if (pageURL === "/") {
      homepage = true;
      navBG = false;
    } else {
      homepage = false;
      navBG = true;
    }

    this.setState({
      navBG,
      homepage
    });
  }

  scrollToTop = () => {  
    var Scroll = require('react-scroll');
    var scroll = Scroll.animateScroll;
    var options = {duration: 500};
    scroll.scrollToTop(options);
};

  componentDidMount() {
    window.addEventListener('load', this.isHomepage);
  }
  
  componentWillUnmount() {
    window.removeEventListener('load', this.isHomepage);
  }

  render() {
    return (
      <nav className={classnames("nav-bar", { "nav-bar--bg": this.state.navBG })}>
        <div className="nav-bar__inner">
          {this.state.homepage && (
            <div className="nav-bar__links">
              <span className="nav-bar__link" onClick={this.scrollToTop}>
                <span className="nav-bar__link-text">Home</span>
              </span>
              <Link className="nav-bar__link" smooth={true} duration={400} to="projects">
                <span className="nav-bar__link-text">Projects</span>
              </Link>
              <Link className="nav-bar__link" smooth={true} duration={500} to="about_me">
                <span className="nav-bar__link-text">About me</span>
              </Link>
              <Link className="nav-bar__link" smooth={true} duration={500} to="experience">
                <span className="nav-bar__link-text">Experience</span>
              </Link>
              <a className="nav-bar__link" href={CV_URL} target="_blank" rel="noreferrer" download="cv.pdf">
                <span className="nav-bar__link-text">
                  CV
                          <span className="material-icons icon__launch"></span>
                </span>
              </a>
            </div>
          )}

          {!this.state.homepage && (
            <div className="nav-bar__links">
              <a className="nav-bar__link" href="/">
                <span className="nav-bar__link-text">Home</span>
              </a>
              <a className="nav-bar__link" href="/#projects">
                <span className="nav-bar__link-text">Projects</span>
              </a>
              <a className="nav-bar__link" href="/#about_me">
                <span className="nav-bar__link-text">About me</span>
              </a>
              <a className="nav-bar__link" href="/#experience">
                <span className="nav-bar__link-text">Experience</span>
              </a>
              <a className="nav-bar__link" href={CV_URL} target="_blank" rel="noreferrer" download="cv.pdf">
                <span className="nav-bar__link-text">
                  CV
                          <span className="material-icons icon__launch"></span>
                </span>
              </a>
            </div>
          )}

          <div className="nav-bar__contact">
            <a className="nav-bar__icon" href="https://www.linkedin.com/in/theomatthews/" target="_blank" rel="noreferrer">
              <svg alt="Linkedin icon" className="nav-bar__linkedin" width="16" height="16" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
                <g>
                  <path d="M15.9963 16V15.9993H16.0003V10.1313C16.0003 7.26065 15.3823 5.04932 12.0263 5.04932C10.413 5.04932 9.33034 5.93465 8.88834 6.77398H8.84167V5.31732H5.65967V15.9993H8.973V10.71C8.973 9.31732 9.237 7.97065 10.9617 7.97065C12.661 7.97065 12.6863 9.55998 12.6863 10.7993V16H15.9963Z" />
                  <path d="M0.26416 5.31799H3.58149V16H0.26416V5.31799Z" />
                  <path d="M1.92133 0C0.860667 0 0 0.860667 0 1.92133C0 2.982 0.860667 3.86067 1.92133 3.86067C2.982 3.86067 3.84267 2.982 3.84267 1.92133C3.842 0.860667 2.98133 0 1.92133 0V0Z" />
                </g>
              </svg>
            </a>
            <a className="nav-bar__icon" href="mailto:contact@theomatthews.co.uk" target="_blank" rel="noreferrer">
              <span className="nav-bar__email material-icons">email</span>
            </a>
          </div>
        </div>
      </nav>
    );
  }
}