import React, { Component } from 'react';
import './Experience.scss';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import Jobs from './Jobs/Jobs'
import Education from './Education/Education'
import Tools from './Tools/Tools'
import CV_URL from '../../variables.js';

export class Experience extends Component {

    render() {
        return (
            <div className="experience page-contents">
                <div className="experience__anchor" id="experience"></div>

                <div className="experience__heading">
                    <h1 className="gradient-text">Experience</h1>
                    
                    <a href={CV_URL} target="_blank" rel="noreferrer" className="experience__cv" download="cv.pdf">
                        <span className="experience__cv-label link-text">View full CV</span>
                        <span className="experience__cv-icon material-icons icon__launch"></span>
                    </a>
                </div>

                <div className="experience__tabs">
                    <Tabs>
                        <TabList>
                            <Tab>
                                <h3 className="experience__tab-heading">Jobs</h3>
                            </Tab>
                            <Tab>
                                <h3 className="experience__tab-heading">Education</h3>
                            </Tab>
                            <Tab>
                                <h3 className="experience__tab-heading">Tools</h3>
                            </Tab>
                        </TabList>
                        <TabPanel>
                            <Jobs />
                        </TabPanel>
                        <TabPanel>
                            <Education />
                        </TabPanel>
                        <TabPanel>
                            <Tools />
                        </TabPanel>
                    </Tabs>
                </div>
            </div>
        )
    }
}

export default Experience;