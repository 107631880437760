import React, { Component } from 'react';
import './Education.scss';

export class Education extends Component {

    render() {
        return (
            <div>
                <p className="education__body">I believe that complete job proficiency is gained through wide ranging, lived experiences, which is why I am grateful to have been exposed to so many different kinds of problems across many different sectors so far in my career. Qualifications and certifications, though, are important foundations and validations of your knowledge. </p>
                <div className="education__main">
                    <div>
                        <div className="education__item">
                            <span className="education__icon material-icons">school</span>
                            <div className="education__detail">
                                <span className="education__title">Computing Science with Industrial Placement degree</span>
                                <span className="education__credentials">Aston University with a placement at IBM as an intern software developer</span>
                            </div>
                        </div>
                        <h4 className="sea-green-text education__certifications">Certifications</h4>
                        <div className="education__item">
                            <span className="education__icon material-icons">school</span>
                            <div className="education__detail">
                                <span className="education__title">Master Nielsen Norman Group (NN/g) certification</span>
                                <span className="education__credentials">
                                    Credential ID 1019713 -
                                    <a href="https://www.nngroup.com/ux-certification/verify/" target="_blank" rel="noreferrer" className="education__link">
                                        <span className="education__link-text">View Credential</span>
                                        <span className="education__link-icon material-icons icon__launch"></span>
                                    </a>
                                </span>
                            </div>
                        </div>
                        <div className="education__item">
                            <span className="education__icon material-icons">school</span>
                            <div className="education__detail">
                                <span className="education__title">BCS - Foundation Certificate in User Experience</span>
                                <span className="education__credentials">
                                    Credential ID Certificate Number: 00425236 - Candidate Number: FC37237383 -
                                    <a href="https://eprofessional.bcs.org/Portal/eCertCheck" target="_blank" rel="noreferrer" className="education__link">
                                        <span className="education__link-text">View Credential</span>
                                        <span className="education__link-icon material-icons icon__launch"></span>
                                    </a>
                                </span>
                            </div>
                        </div>
                        <div className="education__item">
                            <span className="education__icon material-icons">school</span>
                            <div className="education__detail">
                                <span className="education__title">Scrum.org - Professional Scrum with User Experience I</span>
                                <span className="education__credentials">
                                    Credential ID theo.matthews95@gmail.com -
                                    <a href="https://www.scrum.org/certificates/599564" target="_blank" rel="noreferrer" className="education__link">
                                        <span className="education__link-text">View Credential</span>
                                        <span className="education__link-icon material-icons icon__launch"></span>
                                    </a>
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default Education;