import React, { Component } from 'react';
import './Home.scss';
import AboutMe from '../AboutMe/AboutMe'
import HomeHero from '../HomeHero/HomeHero'
import Projects from '../Projects/Projects'
import Experience from '../Experience/Experience'
import BackToTop from '../BackToTop/BackToTop';

export class Home extends Component {

  render() {
    return(
      <div>
          <HomeHero /> 
          <Projects />     
          <AboutMe />
          <Experience />
          <BackToTop />
      </div>
    )
  }
}

export default Home;