import React, { Component } from 'react';
import classnames from "classnames";
import './BackToTop.scss';

export default class BackToTop extends Component {
    constructor(props) {
        super(props);
        this.scrollToTop = this.scrollToTop.bind(this);
    
        this.state = {
          prevScrollpos: window.pageYOffset,
          visible: false,
        };
      }

    scrollToTop() {  
        var Scroll = require('react-scroll');
        var scroll = Scroll.animateScroll;
        var options = {duration: 500};
        scroll.scrollToTop(options);
    };

    handleScroll = () => {
        var { prevScrollpos } = this.state;
        var currentScrollPos = window.pageYOffset;
        var windowWidth = window.innerWidth;
        var alwaysShow = windowWidth > 767;
        var visible = prevScrollpos > currentScrollPos & currentScrollPos > 600 || alwaysShow & currentScrollPos > 600;

        if (currentScrollPos <= 0) {
          visible = false;
        }
    
        this.setState({
          prevScrollpos: currentScrollPos,
          visible
        });
      };

    componentDidMount() {
        window.addEventListener("scroll", this.handleScroll);
      }
      
      componentWillUnmount() {
        window.removeEventListener("scroll", this.handleScroll);
      }

    render() {
        return (
            <div onClick={this.scrollToTop} className={classnames("back-to-top", { "back-to-top--hidden": !this.state.visible })}>
                <span className="back-to-top__icon material-icons">arrow_upward</span>
            </div>
        );
    }
}