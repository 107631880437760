import React, { Component } from 'react';
import PaymentsThumbnail from '../../../assets/projects/payments_thumbnail.jpg'

export class ProjPaymentsCard extends Component {

  render() {
    return (
      <a href="/projects/payments-research" className="project-card">
          <div className="project-card__content">
              <h3 className="project-card__heading">UX research for leading UK payments provider.
                  <span className="project-card__icon material-icons icon__arrow_forward"></span>
              </h3>
              <span className="tag-text project-card__tags-desktop">RESEARCH • INTERACTION DESIGN • VISUAL DESIGN</span>
          </div>
          <img className="project-card__thumbnail" src={PaymentsThumbnail} alt="Investment strategy app on tablet"></img>
          <span className="tag-text project-card__tags-mobile">RESEARCH • INTERACTION DESIGN • VISUAL DESIGN</span>
      </a>
    )
  }
}

export default ProjPaymentsCard;