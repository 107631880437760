import React, { Component } from 'react';
import './Tools.scss';

export class Tools extends Component {

    render() {
        return (
            <div>
                <p className="tools__body">
                    The tools and technologies that you choose to work with can make or break a project and are fundamental to a collaborative Agile design process. Although I have a toolset that I know and trust, I really do value discovering and experimenting with new tools and I am adaptable enough to incorporate any teams' chosen toolset into my process.
                </p>
                <p>Here is a non-exhaustive list of some of the tools I enjoy working:</p>

                <div className="tools__disciplines">
                    <div className="tools__discipline">
                        <h4 className="sea-green-text">Research</h4>
                        <ul className="tools__tools">
                            <li className="tools__tool">
                                <span className="tools__icon material-icons">done</span>
                        ProvenByUsers
                    </li>
                            <li className="tools__tool">
                                <span className="tools__icon material-icons">done</span>
                        OptimalWorkshop
                    </li>
                            <li className="tools__tool">
                                <span className="tools__icon material-icons">done</span>
                        Miro
                    </li>
                        </ul>
                    </div>
                    <div className="tools__discipline">
                        <h4 className="sea-green-text">Design</h4>
                        <ul className="tools__tools">
                            <li className="tools__tool">
                                <span className="tools__icon material-icons">done</span>
                        Figma
                    </li>
                            <li className="tools__tool">
                                <span className="tools__icon material-icons">done</span>
                        Sketch
                    </li>
                            <li className="tools__tool">
                                <span className="tools__icon material-icons">done</span>
                        Azure
                    </li>
                            <li className="tools__tool">
                                <span className="tools__icon material-icons">done</span>
                        Adobe Ps, Ai, Pr
                    </li>
                        </ul>
                    </div>
                    <div className="tools__discipline">
                        <h4 className="sea-green-text">Development</h4>
                        <ul className="tools__tools">
                            <li className="tools__tool">
                                <span className="tools__icon material-icons">done</span>
                        HTML
                    </li>
                            <li className="tools__tool">
                                <span className="tools__icon material-icons">done</span>
                        CSS/SCSS
                    </li>
                            <li className="tools__tool">
                                <span className="tools__icon material-icons">done</span>
                        Git CLI
                    </li>
                            <li className="tools__tool">
                                <span className="tools__icon material-icons">done</span>
                        Bootstrap
                    </li>
                        </ul>
                    </div>
                </div>
            </div>
        )
    }
}

export default Tools;