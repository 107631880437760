import React, { Component } from "react";
import classnames from "classnames";
import "./NavBarMobile.scss";
import { Link } from "react-scroll";
import CV_URL from '../../variables.js';

export default class NavbarMobile extends Component {
    container = React.createRef();

    constructor(props) {
        super(props);

        this.state = {
            navLight: true,
            open: false,
            pageURL: window.location.pathname,
            homepage: true
        };
    }


    isHomepage = () => {
        var pageURL = window.location.pathname;
        var navLight = this.state.navLight;
        var homepage = this.state.homepage;

        if (pageURL !== "/") {
            homepage = false;
            navLight = false;
        }

        this.setState({
            navLight,
            homepage
        });
    }

    handleButtonClick = () => {
        this.setState((state) => {
            return {
                open: !state.open,
            };
        });
    };

    scrollToTop = () => {
        var Scroll = require('react-scroll');
        var scroll = Scroll.animateScroll;
        var options = { duration: 500 };
        scroll.scrollToTop(options);


        this.handleButtonClick();
    };

    handleClickOutside = (event) => {
        if (
            this.container.current &&
            !this.container.current.contains(event.target)
        ) {
            this.setState({
                open: false,
            });
        }
    };

    componentDidMount() {
        window.addEventListener('load', this.isHomepage);
        document.addEventListener("mousedown", this.handleClickOutside);
    }

    componentWillUnmount() {
        window.removeEventListener('load', this.isHomepage);
        document.removeEventListener("mousedown", this.handleClickOutside);
    }

    render() {
        return (
            <nav
                className={classnames("nav-bar-mobile", { "nav-bar-mobile--light": this.state.navLight })}
                ref={this.container}
            >
                <button href="/" className={classnames("nav-bar-mobile__launcher", { "hidden": this.state.open })} onClick={this.handleButtonClick}>
                    <span className="material-icons nav-bar-mobile__launcher-icon">menu</span>
                </button>

                <button href="/" className={classnames("nav-bar-mobile__launcher nav-bar-mobile__close-icon", { "hidden": !this.state.open })} onClick={this.handleButtonClick}>
                    <span className="material-icons nav-bar-mobile__launcher-icon">close</span>
                </button>

                {this.state.homepage && (
                    <div className={classnames("nav-bar-mobile__expanded", { "hidden": !this.state.open })}>
                        <span className="nav-bar-mobile__link" onClick={this.scrollToTop}>
                            <h4 className="nav-bar-mobile__link-text">Home</h4>
                        </span>
                        <Link className="nav-bar-mobile__link" smooth={true} duration={400} to="projects" onClick={this.handleButtonClick}>
                            <h4 className="nav-bar-mobile__link-text">Projects</h4>
                        </Link>
                        <Link className="nav-bar-mobile__link" smooth={true} duration={500} to="about_me" onClick={this.handleButtonClick}>
                            <h4 className="nav-bar-mobile__link-text">About me</h4>
                        </Link>
                        <Link className="nav-bar-mobile__link" smooth={true} duration={500} to="experience" onClick={this.handleButtonClick}>
                            <h4 className="nav-bar-mobile__link-text">Experience</h4>
                        </Link>
                        <a className="nav-bar-mobile__link" href={CV_URL} target="_blank" rel="noreferrer" download="cv.pdf" onClick={this.handleButtonClick}>
                            <h4 className="nav-bar-mobile__link-text cv-link">CV
                                <span className="material-icons icon__launch"></span>
                            </h4>
                        </a>

                        <div className="nav-bar-mobile__contact">
                            <a className="nav-bar-mobile__contact-icon" href="https://www.linkedin.com/in/theomatthews/" target="_blank" rel="noreferrer">
                                <svg alt="Linkedin icon" className="nav-bar-mobile__linkedin" width="16" height="16" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
                                    <g>
                                        <path d="M15.9963 16V15.9993H16.0003V10.1313C16.0003 7.26065 15.3823 5.04932 12.0263 5.04932C10.413 5.04932 9.33034 5.93465 8.88834 6.77398H8.84167V5.31732H5.65967V15.9993H8.973V10.71C8.973 9.31732 9.237 7.97065 10.9617 7.97065C12.661 7.97065 12.6863 9.55998 12.6863 10.7993V16H15.9963Z" />
                                        <path d="M0.26416 5.31799H3.58149V16H0.26416V5.31799Z" />
                                        <path d="M1.92133 0C0.860667 0 0 0.860667 0 1.92133C0 2.982 0.860667 3.86067 1.92133 3.86067C2.982 3.86067 3.84267 2.982 3.84267 1.92133C3.842 0.860667 2.98133 0 1.92133 0V0Z" />
                                    </g>
                                </svg>
                            </a>
                            <a className="nav-bar-mobile__contact-icon" href="mailto:contact@theomatthews.co.uk" target="_blank" rel="noreferrer">
                                <span className="nav-bar-mobile__email material-icons">email</span>
                            </a>
                        </div>
                    </div>
                )}

                {!this.state.homepage && (
                    <div className={classnames("nav-bar-mobile__expanded", { "hidden": !this.state.open })}>
                        <a className="nav-bar-mobile__link" href="/" onClick={this.handleButtonClick}>
                            <h4 className="nav-bar-mobile__link-text">Home</h4>
                        </a>
                        <a className="nav-bar-mobile__link" href="/#projects" onClick={this.handleButtonClick}>
                            <h4 className="nav-bar-mobile__link-text">Projects</h4>
                        </a>
                        <a className="nav-bar-mobile__link" href="/#about_me" onClick={this.handleButtonClick}>
                            <h4 className="nav-bar-mobile__link-text">About me</h4>
                        </a>
                        <a className="nav-bar-mobile__link" href="/#experience" onClick={this.handleButtonClick}>
                            <h4 className="nav-bar-mobile__link-text">Experience</h4>
                        </a>
                        <a className="nav-bar-mobile__link" href={CV_URL} target="_blank" rel="noreferrer" download="cv.pdf" onClick={this.handleButtonClick}>
                            <h4 className="nav-bar-mobile__link-text cv-link">CV
                                <span className="material-icons icon__launch"></span>
                            </h4>
                        </a>

                        <div className="nav-bar-mobile__contact">
                            <a className="nav-bar-mobile__contact-icon" href="https://www.linkedin.com/in/theomatthews/" target="_blank" rel="noreferrer">
                                <svg alt="Linkedin icon" className="nav-bar-mobile__linkedin" width="16" height="16" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
                                    <g>
                                        <path d="M15.9963 16V15.9993H16.0003V10.1313C16.0003 7.26065 15.3823 5.04932 12.0263 5.04932C10.413 5.04932 9.33034 5.93465 8.88834 6.77398H8.84167V5.31732H5.65967V15.9993H8.973V10.71C8.973 9.31732 9.237 7.97065 10.9617 7.97065C12.661 7.97065 12.6863 9.55998 12.6863 10.7993V16H15.9963Z" />
                                        <path d="M0.26416 5.31799H3.58149V16H0.26416V5.31799Z" />
                                        <path d="M1.92133 0C0.860667 0 0 0.860667 0 1.92133C0 2.982 0.860667 3.86067 1.92133 3.86067C2.982 3.86067 3.84267 2.982 3.84267 1.92133C3.842 0.860667 2.98133 0 1.92133 0V0Z" />
                                    </g>
                                </svg>
                            </a>
                            <a className="nav-bar-mobile__contact-icon" href="mailto:contact@theomatthews.co.uk" target="_blank" rel="noreferrer">
                                <span className="nav-bar-mobile__email material-icons">email</span>
                            </a>
                        </div>
                    </div>
                )}
            </nav>
        );
    }
}