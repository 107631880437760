import React, { useState, Component } from 'react';
import './Timezones.scss';
import moment from 'moment-timezone';
// import doSomething from "./function";
import Slider, { Range } from 'rc-slider';
import 'rc-slider/assets/index.css';
import OxfordImage from '../../assets/timezones/oxford_img.png';
import SydneyImage from '../../assets/timezones/sydney_img.png';
import TimezoneBG from '../../assets/timezones/timezone-bg.svg';

const timeFormat = 'HH:mm_ddd D MMM YYYY';


export class Timezones extends Component {

  state = {
    sliderValue: 0
  };

  handleChange = sliderValue => {
    this.setState({ sliderValue });
  };

  render() {
    const { sliderValue } = this.state;

    const sydneyRefTime = moment().tz('Australia/Sydney').format();
    const oxfordRefTime = moment().tz('Europe/London').format();

    const [timeSydney, dateSydney] = moment(sydneyRefTime)
    .tz('Australia/Sydney')
    .add(sliderValue, 'hours')
    .format(timeFormat)
    .split('_');
    
    const [timeOxford, dateOxford] = moment(oxfordRefTime)
      .tz('Europe/London')
      .add(sliderValue, 'hours')
      .format(timeFormat)
      .split('_');

    return(
      <div className="timezones">
        <img className="timezone-bg" src={TimezoneBG} alt="Timezone meridian lines" />
        <div className='main-content'>
          <span class="timezones__tag">OXFORD · SYDNEY</span>
          <h1>What's the Time?</h1>

          <div className="timezones__card timezones__card--oxford">
            <div className="timezones__illustration--oxford">
              <span className='region_title'>Oxford</span>
              <img className="region_img" src={OxfordImage} alt="Illustration of Oxford City" />
            </div>
            <div className='region_time_date_container'>
              <span className="region_time">{timeOxford}</span>
              <span className="region_date">{dateOxford}</span>
            </div>
          </div>

          <div className="timezones__card timezones__card--sydney">
            <div className="timezones__illustration--sydney">
              <span className="region_title">Sydney</span>
              <img className="region_img" src={SydneyImage} alt="Illustration of Sydney" />
            </div>
            <div className='region_time_date_container'>
              <span className="region_time">{timeSydney}</span>
              <span className="region_date">{dateSydney}</span>
            </div>
          </div>
        </div>
        <div className="slider-fixed-container">
          <div className='slider-container'>
            <span className='slider-title'>Slide to adjust the time</span>
            <span className='slider-diff'>{sliderValue} hours difference</span>
            <Slider
              className='slider'
              min={-24}
              max={24}
              onChange={this.handleChange}
              defaultValue={0}
              tipFormatter={value => <span className="tooltip">{value}€</span>}
            />
            <span className='slider-bottom'></span>
          </div>
        </div>
      </div>
    )
  }
}

export default Timezones;