import React, { Component } from 'react';
import './ProjectsHome.scss';
import ProjPharmaCard from './ProjPharma/ProjPharmaCard'
import ProjFundCard from './ProjFund/ProjFundCard'
import ProjPaymentsCard from './ProjPayments/ProjPaymentsCard'

export class Projects extends Component {

    render() {
        return (
            <div>
                <div className="page-contents">
                    <div className="project__anchor" id="projects"></div>
                    <h1 className="project__heading gradient-text">Projects</h1>
                    <ProjPharmaCard />
                    <ProjFundCard />
                    <ProjPaymentsCard />
                </div>
            </div>
        )
    }
}

export default Projects;