import React, { Component } from 'react';
import './ProjPayments.scss';
import BackToTop from '../../BackToTop/BackToTop';
import Border from '../../../assets/border.svg'
import { Gallery, Item } from 'react-photoswipe-gallery'
import 'photoswipe/dist/photoswipe.css'
import HeroPic from '../../../assets/projects/payments/payments-laptop.png'
import Payments_CompetitorAnalysis from '../../../assets/projects/payments/payments__competitor-analysis.jpg'
import Payments_CompetitorAnalysis_Thumbnail from '../../../assets/projects/payments/payments__competitor-analysis--thumbnail.jpg'
import Payments_CompetitorCharts from '../../../assets/projects/payments/payments__competitor-charts.jpg'
import Payments_CompetitorCharts_Thumbnail from '../../../assets/projects/payments/payments__competitor-charts--thumbnail.jpg'
import Payments_CardSort from '../../../assets/projects/payments/payments__card-sort.jpg'
import Payments_CardSort_Thumbnail from '../../../assets/projects/payments/payments__card-sort--thumbnail.jpg'
import Payments_Dendrogram from '../../../assets/projects/payments/payments__dendrogram.jpg'
import Payments_Dendrogram_Thumbnail from '../../../assets/projects/payments/payments__dendrogram--thumbnail.jpg'
import Payments_TreeTest from '../../../assets/projects/payments/payments__tree-test.jpg'
import Payments_TreeTest_Thumbnail from '../../../assets/projects/payments/payments__tree-test--thumbnail.jpg'
import Payments_Sketch from '../../../assets/projects/payments/payments__sketch.jpg'
import Payments_Sketch_Thumbnail from '../../../assets/projects/payments/payments__sketch--thumbnail.jpg'
import Payments_CaseStudy from '../../../assets/projects/payments/payments__case-study.jpg'
import Payments_CaseStudy_Thumbnail from '../../../assets/projects/payments/payments__case-study--thumbnail.jpg'
import Payments_BizPersona from '../../../assets/projects/payments/payments__biz-persona.jpg'
import Payments_BizPersona_Thumbnail from '../../../assets/projects/payments/payments__biz-persona--thumbnail.jpg'
import Payments_DevPersona from '../../../assets/projects/payments/payments__dev-persona.jpg'
import Payments_DevPersona_Thumbnail from '../../../assets/projects/payments/payments__dev-persona--thumbnail.jpg'
import Payments_BizUJ from '../../../assets/projects/payments/payments__biz-uj.jpg'
import Payments_BizUJ_Thumbnail from '../../../assets/projects/payments/payments__biz-uj--thumbnail.jpg'
import Payments_DevUJ from '../../../assets/projects/payments/payments__dev-uj.jpg'
import Payments_DevUJ_Thumbnail from '../../../assets/projects/payments/payments__dev-uj--thumbnail.jpg'
import ProjFundCard from '../ProjFund/ProjFundCard'
import ProjPharmaCard from '../ProjPharma/ProjPharmaCard'

const Gallery1 = () => (
  <Gallery shareButton={false}>
    <Item
      original={Payments_CompetitorAnalysis}
      thumbnail={Payments_CompetitorAnalysis_Thumbnail}
      width="1406"
      height="860"
      title="Competitor analysis spreadsheet"
    >
      {({ ref, open }) => (
        <div className="proj__gallery--2-thumbnail-container">
          <img className="proj__gallery--2-thumbnail" ref={ref} onClick={open} src={Payments_CompetitorAnalysis_Thumbnail} alt="Competitor analysis spreadsheet" />
          <span className="proj__gallery-caption">Competitor analysis</span>
        </div>
      )}
    </Item>
    <Item
      original={Payments_CompetitorCharts}
      thumbnail={Payments_CompetitorCharts_Thumbnail}
      width="1406"
      height="860"
      title="Summary heuristics charts"
    >
      {({ ref, open }) => (
        <div className="proj__gallery--2-thumbnail-container" >
          <img className="proj__gallery--2-thumbnail" ref={ref} onClick={open} src={Payments_CompetitorCharts_Thumbnail} alt="Summary heuristics charts" />
          <span className="proj__gallery-caption">Summary heuristics charts</span>
        </div>
      )}
    </Item>
  </Gallery>
)

const Gallery2 = () => (
  <Gallery shareButton={false}>
    <Item
      original={Payments_CardSort}
      thumbnail={Payments_CardSort_Thumbnail}
      width="1400"
      height="720"
      title="Card sort exercise"
    >
      {({ ref, open }) => (
        <div className="proj__gallery--1-thumbnail-container">
          <img className="proj__gallery--1-thumbnail" ref={ref} onClick={open} src={Payments_CardSort_Thumbnail} alt="Card sort exercise" />
          <span className="proj__gallery-caption">Card sort exercise</span>
        </div>
      )}
    </Item>
  </Gallery>
)

const Gallery3 = () => (
  <Gallery shareButton={false}>
    <Item
      original={Payments_Dendrogram}
      thumbnail={Payments_Dendrogram_Thumbnail}
      width="1376"
      height="1070"
      title="Dendrogram"
    >
      {({ ref, open }) => (
        <div className="proj__gallery--2-thumbnail-container">
          <img className="proj__gallery--2-thumbnail" ref={ref} onClick={open} src={Payments_Dendrogram_Thumbnail} alt="Dendrogram" />
          <span className="proj__gallery-caption">Dendrogram</span>
        </div>
      )}
    </Item>
    <Item
      original={Payments_TreeTest}
      thumbnail={Payments_TreeTest_Thumbnail}
      width="1376"
      height="1070"
      title="Tree test exercise"
    >
      {({ ref, open }) => (
        <div className="proj__gallery--2-thumbnail-container" >
          <img className="proj__gallery--2-thumbnail" ref={ref} onClick={open} src={Payments_TreeTest_Thumbnail} alt="Tree test exercise" />
          <span className="proj__gallery-caption">Tree test exercise</span>
        </div>
      )}
    </Item>
  </Gallery>
)

const Gallery4 = () => (
  <Gallery shareButton={false}>
    <Item
      original={Payments_Sketch}
      thumbnail={Payments_Sketch_Thumbnail}
      width="643"
      height="964"
      title="Sketch of case studies component"
    >
      {({ ref, open }) => (
        <div className="proj__gallery--2-thumbnail-container">
          <img className="proj__gallery--2-thumbnail" ref={ref} onClick={open} src={Payments_Sketch_Thumbnail} alt="Sketch of case studies component" />
          <span className="proj__gallery-caption">Case studies sketch</span>
        </div>
      )}
    </Item>
    <Item
      original={Payments_CaseStudy}
      thumbnail={Payments_CaseStudy_Thumbnail}
      width="1400"
      height="964"
      title="High-res case studies component in page"
    >
      {({ ref, open }) => (
        <div className="proj__gallery--2-thumbnail-container" >
          <img className="proj__gallery--2-thumbnail" ref={ref} onClick={open} src={Payments_CaseStudy_Thumbnail} alt="High-res case studies component in page" />
          <span className="proj__gallery-caption">High-res case studies</span>
        </div>
      )}
    </Item>
  </Gallery>
)


const Gallery5 = () => (
  <Gallery shareButton={false}>
    <div className="proj-gallery__container">
      <table className="proj-gallery proj-gallery--2by2">
        <thead>
          <tr>
            <th className="proj-gallery__sticky"></th>
            <th className="proj-gallery__heading-top"><h5>Persona</h5></th>
            <th className="proj-gallery__heading-top"><h5>User journey</h5></th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <th className="proj-gallery__sticky"><div className="proj-gallery__heading-left"><h5>Businessman</h5></div></th>
            <td>
              <Item
                original={Payments_BizPersona}
                thumbnail={Payments_BizPersona_Thumbnail}
                width="1190"
                height="842"
                title="Small business owner persona"
              >
                {({ ref, open }) => (
                  <div className="proj-gallery__thumbnail">
                    <img className="proj__gallery--3-thumbnail" ref={ref} onClick={open} src={Payments_BizPersona_Thumbnail} alt="Small business owner persona" />
                  </div>
                )}
              </Item>
            </td>
            <td>
              <Item
                original={Payments_BizUJ}
                thumbnail={Payments_BizUJ_Thumbnail}
                width="1190"
                height="842"
                title="Small business owner user journey"
              >
                {({ ref, open }) => (
                  <div className="proj-gallery__thumbnail">
                    <img className="proj__gallery--3-thumbnail" ref={ref} onClick={open} src={Payments_BizUJ_Thumbnail} alt="Small business owner user journey" />
                  </div>
                )}
              </Item>
            </td>
          </tr>
          <tr>
            <th className="proj-gallery__sticky"><div className="proj-gallery__heading-left"><h5>Developer</h5></div></th>
            <td>
              <Item
                original={Payments_DevPersona}
                thumbnail={Payments_DevPersona_Thumbnail}
                width="1190"
                height="842"
                title="Developer persona"
              >
                {({ ref, open }) => (
                  <div className="proj-gallery__thumbnail">
                    <img className="proj__gallery--3-thumbnail" ref={ref} onClick={open} src={Payments_DevPersona_Thumbnail} alt="Developer persona" />
                  </div>
                )}
              </Item>
            </td>
            <td>
              <Item
                original={Payments_DevUJ}
                thumbnail={Payments_DevUJ_Thumbnail}
                width="1190"
                height="842"
                title="Developer user journey"
              >
                {({ ref, open }) => (
                  <div className="proj-gallery__thumbnail">
                    <img className="proj__gallery--3-thumbnail" ref={ref} onClick={open} src={Payments_DevUJ_Thumbnail} alt="Developer user journey" />
                  </div>
                )}
              </Item>
            </td>
          </tr>
          <tr>
            <td className="proj-gallery__sticky proj-gallery__padding-row"></td>
            <td></td>
            <td></td>
            <td></td>
          </tr>
        </tbody>
      </table>
    </div>
  </Gallery>
)


export class Footer extends Component {

  render() {
    return (
      <div className="proj__container">
        <div className="page-contents">
          <span className="proj__breadcrumb"><a className="link-text" href="/">Home</a> / <a className="link-text" href="/#projects">Projects</a> / </span>
          <h1 className="gradient-text">UX research for leading UK payments provider.</h1>

          <div>
            <div className="proj__meta">
              <span className="proj__meta-icon material-icons">person</span>
              <span className="proj__meta-text">User researcher &#38; interaction + visual designer</span>
            </div>

            <div className="proj__meta">
              <span className="proj__meta-icon material-icons">groups</span>
              <span className="proj__meta-text">2 UX designers &#38; product owner</span>
            </div>

            <div className="proj__meta">
              <span className="proj__meta-icon material-icons">watch_later</span>
              <span className="proj__meta-text">5 weeks</span>
            </div>
          </div>

          <div className="proj__intro">
            <div>
              <p>A client with a speciality in payments wanted to reimagine their marketing website to attract new types of customers. In the sector, two of the main customer types are developers and small business owners. The existing site was not optimised to attract these customers as it did not have content or a visual design that would appeal to them, meaning they were losing many potential sales.</p>
              <p>The client relied on traditional sales methods such as face to face meetings to drive business. Competitors though were finding success in creating websites that enabled customers to ‘self-serve’, enabling much greater growth thanks to the scalability of their solutions. The client recognised the deficiencies of their site and tasked us with researching their competitors and new target customers, then providing them with recommendations on UX strategy and website design.</p>
            </div>

            <img className="proj__hero-pic" src={HeroPic} alt="Laptop with website running on it"></img>
          </div>

          <div className="proj__strategy">
            <h3 className="proj__heading-3 sea-green-text">Strategy</h3>
            <p>To enable the client to meet their goals of redesigning their website, we came up with the following strategy:</p>
            <ul className="bullet-list proj__bullet-list">
              <li><span className="bullet-list__text">Conduct a competitor analysis;</span></li>
              <li><span className="bullet-list__text">Research the general domain;</span></li>
              <li><span className="bullet-list__text">Card sort and tree test to create new information architecture;</span></li>
              <li><span className="bullet-list__text">Interview users;</span></li>
              <li><span className="bullet-list__text">Create proto-personas and user journey maps;</span></li>
              <li><span className="bullet-list__text">Create sample set of component and page designs;</span></li>
              <li><span className="bullet-list__text">Create report with recommendations and strategy;</span></li>
            </ul>
            <p>One of the main constraints of the project was the client was unwilling to give us access to real users. We got around this by utilising internal staff members at both our consultancy and their company.</p>
          </div>

          <div className="proj__solutions">
            <h3 className="proj__heading-3 sea-green-text">Solutions</h3>
            <p>We were challenged with many problems during the project, so instead of detailing them all, I will discuss the three we solved that I believe are most interesting.</p>
            <div className="table-card__overflow-container--1">
              <div className="table-card__overflow-container--2">
                <div className="table-card__container">
                  <table className="table-card">
                    <thead>
                      <tr>
                        <th className="table-card__heading"><h4 className="sea-green-text">Problem</h4></th>
                        <th className="table-card__heading"><h4 className="sea-green-text">Solution</h4></th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td className="table-card__data table-card__numbered-cell">
                          <h5 className="sea-green-text">1.</h5>
                          <p className="table-card__text">Client knew who the competition was, but didn’t understand what exactly they were doing better</p>
                        </td>
                        <td className="table-card__data">
                          <p className="table-card__text">Conduct <strong>competitor analysis</strong> with a focus on user journeys and solution design</p>
                        </td>
                      </tr>

                      <tr>
                        <td className="table-card__data table-card__numbered-cell">
                          <h5 className="sea-green-text">2.</h5>
                          <p className="table-card__text">Existing <strong>information architecture</strong> (IA) was not optimised for users or content</p>
                        </td>
                        <td className="table-card__data">
                          <p className="table-card__text">Redesign IA through a card sort exercise and validate with a tree test</p></td>
                      </tr>

                      <tr>
                        <td className="table-card__data table-card__numbered-cell">
                          <h5 className="sea-green-text">3.</h5>
                          <p className="table-card__text">Client wanted to know how to convert visitors to the website into customers</p>
                        </td>
                        <td className="table-card__data">
                          <p className="table-card__text">Combine all research to create <strong>personas &#38; user journey maps</strong> showing the happy path</p></td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>

        <img className="jagged-border--top" src={Border} alt="border"></img>
        <div className="jagged-border__inner-container">
          <div className="page-contents proj__problem">
            <h2 className="proj__problem-heading gradient-text">Problem 1.</h2>
            <h4 className="proj__problem-sub-heading sea-green-text">Understanding the competition</h4>
            <p>The client had a good idea who their competition was. What they didn’t know was, in UX terms, what the competition was doing better. Performing a competitor analysis would give me the time to go through each competitor, and produce a resource that would summarise my findings.</p>
            <p>Based on the discussions I had had with the client, I drafted a spreadsheet that would be used for the analysis. The most important columns were:</p>
            <ul className="bullet-list proj__bullet-list">
              <li><span className="bullet-list__text">Four heuristics to measure site performance;</span></li>
              <li><span className="bullet-list__text">Strengths;</span></li>
              <li><span className="bullet-list__text">Weaknesses;</span></li>
              <li><span className="bullet-list__text">Takeaways for the client.</span></li>
            </ul>
            <p>The heuristics I defined were based on priorities the client had for their new site. For each competitor's site, I would give a score, determining aspects like how well the site was suited to the different user types, and how easy it is to sign up to the service. These scores would then provide a quick comparison to show how all the competitors ranked against each other. I visualised those scores into bar charts to make to make it even easier to compare:</p>
            <div className="proj__gallery">
              <Gallery1 />
            </div>
            <p>Whilst performing the analysis, I would place myself in the shoes of each of the different user types, imagining the journeys they would be taking. This, along with my understanding of UX best practices, helped me determine the strengths and weaknesses of each competitor, and then provide recommendations to the client.</p>
            <p>When presented with the spreadsheet, the client was delighted with the analysis. From it, they would be able to select the best ideas from the competitors who had invested significantly in their platforms. Equally important though was providing guidance on what not to do based on the shortcomings of some competitors. It also provided us with a foundation for the remainder of the project work.</p>
          </div>
        </div>
        <img className="jagged-border--bottom" src={Border} alt="border"></img>
        <div className="page-contents proj__problem">
          <h2 className="proj__problem-heading gradient-text">Problem 2.</h2>
          <h4 className="proj__problem-sub-heading sea-green-text">Information architecture</h4>
          <p>The client had a number of problems with their existing information architecture (IA) which meant that target customers wouldn’t feel supported. For example, there wasn’t a dedicated menu item for developers, something the most successful competitors had. From a small business owner’s perspective, the site and navigation was laden with technical jargon, making it difficult to find what they needed.</p>
          <p>To fix these problems and create a user-centred IA, I ran an open card sort exercise. Ideally, the participants would’ve been end-users, but given the client’s constraints, we used developers from our organisation and customer service reps from theirs to complete the exercise. The exercise was conducted remotely using the research tool ProvenByUsers, with a total of 15 participants contributing.</p>
          <p>The cards that participants sorted were based on the new content sections for the website. Those new content sections were influenced by the competitor analysis and the client’s priorities for the new site. Once the user had sorted the cards into groups, they were asked to name those groups in a way that best described the selection.</p>
          <div className="proj__gallery">
            <Gallery2 />
          </div>
          <p>Analysing the results proved challenging, but using the dendrogram and other analysis functions in the test tool, I created an IA based on the participants’ input. Being able to use the group names participants had created was a big help when creating this. To validate this new structure, I ran a tree test using the same tool. The results from this test were satisfactory, with most sections scoring above 80% in terms of findability.</p>
          <div className="proj__gallery">
            <Gallery3 />
          </div>
        </div>


        <img className="jagged-border--top" src={Border} alt="border"></img>
        <div className="jagged-border__inner-container">
          <div className="page-contents proj__problem">
            <h2 className="proj__problem-heading gradient-text">Problem 3.</h2>
            <h4 className="proj__problem-sub-heading sea-green-text">Selling to customers</h4>
            <p>Ultimately, the client wanted to know how to sell to their target customers. To answer this, we first needed to get some user input. The constraints set by the client meant we couldn’t have access to their customers. Given one of the main target customer types was developers, I interviewed developers at our organisation that had specific experience with payment gateways, which proved very insightful. For the small business owner, the other UX designer on the project actually owned a small business themselves, and so was able to provide valuable insight about his experience.</p>
            <p>To extract as much insight as possible, we performed an exercise where for each user type, we listed out all the questions they might have when visiting the site. Then for each question they might have, we would list out a few solutions that would help answer their question. To extend upon this, we then spent time sketching out what those solutions might look like, in simple lo-fi drawings, then followed up with some full page screen designs. Below are some examples of this:</p>
            <div className="proj__gallery">
              <Gallery4 />
            </div>
            <p>To encapsulate all the insight we had gained so far, I created proto-personas and user journey maps. The proto-personas were a simple summary of the needs, behaviours and pain points of each of the user types. The user journey maps I created contained the happy path of a customer through the client’s site. In the opportunities section, I provided recommendations based on what I had heard and observed the competition was doing well.</p>
            <p>When presented with these deliverables, the client was particularly fascinated by the insights provided in the user journey maps. Insights like ways to improve the quality of experience for developers by giving them access to lots of test cards.</p>
          </div>
          <Gallery5 />
        </div>
        <img className="jagged-border--bottom" src={Border} alt="border"></img>

        <div className="page-contents proj__problem">
          <h2 className="proj__problem-heading proj__conclusion gradient-text">Conclusion</h2>
          <p>The goal of the project was to help the client begin the process of redesigning their website. With the UX deliverables and report we provided, the client feels very able to continue to their goal, knowing they have all the insight required to develop a website that can meet and surpass their competitors.</p>
        </div>

        <div className="more-projects">
          <div className="page-contents">
            <h3 className="more-projects__heading gradient-text">More projects</h3>
          </div>
          <div className="project-card__horizontal-scroll-container">
            <div className="project-card__horizontal-scroll">
              <ProjFundCard />
              <ProjPharmaCard />
            </div>
          </div>
        </div>
        <BackToTop />
      </div>
    )
  }
}

export default Footer;