import React, { Component } from 'react';
import '../Project.scss';
import './ProjFund.scss';
import BackToTop from '../../BackToTop/BackToTop';
import Border from '../../../assets/border.svg'
import { Gallery, Item } from 'react-photoswipe-gallery'
import 'photoswipe/dist/photoswipe.css'
import HeroPic from '../../../assets/projects/fund/fund-laptop.png'
import Fund_EventsImport from '../../../assets/projects/fund/fund__events-import.jpg'
import Fund_EventsImport_Thumbnail from '../../../assets/projects/fund/fund__events-import--thumbnail.jpg'
import FundFirst_EventsEdit from '../../../assets/projects/fund/fund-first__events-edit.jpg'
import FundFirst_EventsEdit_Thumbnail from '../../../assets/projects/fund/fund-first__events-edit--thumbnail.jpg'
import FundSecond_EventsEdit from '../../../assets/projects/fund/fund-second__events-edit.jpg'
import FundSecond_EventsEdit_Thumbnail from '../../../assets/projects/fund/fund-second__events-edit--thumbnail.jpg'
import Fund_EventsView from '../../../assets/projects/fund/fund__events-view.jpg'
import Fund_EventsView_Thumbnail from '../../../assets/projects/fund/fund__events-view--thumbnail.jpg'
import Fund_ModellingExcel from '../../../assets/projects/fund/fund__modelling-excel.jpg'
import Fund_ModellingExcel_Thumbnail from '../../../assets/projects/fund/fund__modelling-excel--thumbnail.jpg'
import FundFirst_Modelling from '../../../assets/projects/fund/fund-first__modelling.jpg'
import FundFirst_Modelling_Thumbnail from '../../../assets/projects/fund/fund-first__modelling--thumbnail.jpg'
import FundSecond_Modelling from '../../../assets/projects/fund/fund-second__modelling.jpg'
import FundSecond_Modelling_Thumbnail from '../../../assets/projects/fund/fund-second__modelling--thumbnail.jpg'
import ProjPharmaCard from '../ProjPharma/ProjPharmaCard'
import ProjPaymentsCard from '../ProjPayments/ProjPaymentsCard'


const Gallery1 = () => (
  <Gallery shareButton={false}>
    <Item
      original={Fund_EventsImport}
      thumbnail={Fund_EventsImport_Thumbnail}
      width="1267"
      height="1012"
      title="Importing events with date range picker"
    >
      {({ ref, open }) => (
        <div className="proj__gallery--1-thumbnail-container">
          <img className="proj__gallery--1-thumbnail" ref={ref} onClick={open} src={Fund_EventsImport_Thumbnail} alt="Importing events with date range picker" />
          <span className="proj__gallery-caption">Importing events</span>
        </div>
      )}
    </Item>
  </Gallery>
)

const Gallery2 = () => (
  <Gallery shareButton={false}>
    <Item
      original={FundFirst_EventsEdit}
      thumbnail={FundFirst_EventsEdit_Thumbnail}
      width="1195"
      height="955"
      title="First iteration of edit events"
    >
      {({ ref, open }) => (
        <div className="proj__gallery--2-thumbnail-container">
          <img className="proj__gallery--2-thumbnail" ref={ref} onClick={open} src={FundFirst_EventsEdit_Thumbnail} alt="First iteration of edit events" />
          <span className="proj__gallery-caption">First iteration</span>
        </div>
      )}
    </Item>
    <Item
      original={FundSecond_EventsEdit}
      thumbnail={FundSecond_EventsEdit_Thumbnail}
      width="1195"
      height="955"
      title="Second iteration of edit events"
    >
      {({ ref, open }) => (
        <div className="proj__gallery--2-thumbnail-container" >
          <img className="proj__gallery--2-thumbnail" ref={ref} onClick={open} src={FundSecond_EventsEdit_Thumbnail} alt="Second iteration of edit events" />
          <span className="proj__gallery-caption">Second iteration</span>
        </div>
      )}
    </Item>
  </Gallery>
)

const Gallery3 = () => (
  <Gallery shareButton={false}>
    <Item
      original={Fund_EventsView}
      thumbnail={Fund_EventsView_Thumbnail}
      width="1195"
      height="955"
      title="View events with additional star button to register interest in event"
    >
      {({ ref, open }) => (
        <div className="proj__gallery--1-thumbnail-container">
          <img className="proj__gallery--1-thumbnail" ref={ref} onClick={open} src={Fund_EventsView_Thumbnail} alt="View events with additional star button to register interest in event" />
          <span className="proj__gallery-caption">View of the events calendar</span>
        </div>
      )}
    </Item>
  </Gallery>
)

const Gallery4 = () => (
  <Gallery shareButton={false}>
    <Item
      original={Fund_ModellingExcel}
      thumbnail={Fund_ModellingExcel_Thumbnail}
      width="1200"
      height="832"
      title="Excel wireframe provided by client for new modelling section"
    >
      {({ ref, open }) => (
        <div className="proj__gallery--2-thumbnail-container">
          <img className="proj__gallery--2-thumbnail" ref={ref} onClick={open} src={Fund_ModellingExcel_Thumbnail} alt="Excel wireframe provided by client for new modelling section" />
          <span className="proj__gallery-caption">Excel wireframe</span>
        </div>
      )}
    </Item>
    <Item
      original={FundFirst_Modelling}
      thumbnail={FundFirst_Modelling_Thumbnail}
      width="1200"
      height="832"
      title="My first lo-fi wireframe for new modelling section"
    >
      {({ ref, open }) => (
        <div className="proj__gallery--2-thumbnail-container" >
          <img className="proj__gallery--2-thumbnail" ref={ref} onClick={open} src={FundFirst_Modelling_Thumbnail} alt="My first lo-fi wireframe for new modelling section" />
          <span className="proj__gallery-caption">My first wireframe design</span>
        </div>
      )}
    </Item>
  </Gallery>
)

const Gallery5 = () => (
  <Gallery shareButton={false}>
    <Item
      original={FundSecond_Modelling}
      thumbnail={FundSecond_Modelling_Thumbnail}
      width="1400"
      height="1057"
      title="Modelling screen today"
    >
      {({ ref, open }) => (
        <div className="proj__gallery--1-thumbnail-container">
          <img className="proj__gallery--1-thumbnail" ref={ref} onClick={open} src={FundSecond_Modelling_Thumbnail} alt="Modelling screen today" />
          <span className="proj__gallery-caption">Modelling page today</span>
        </div>
      )}
    </Item>
  </Gallery>
)


export class ProjFund extends Component {

  render() {
    return (
      <div className="proj__container">
        <div className="page-contents">
          <span className="proj__breadcrumb"><a className="link-text" href="/">Home</a> / <a className="link-text" href="/#projects">Projects</a> / </span>
          <h1 className="gradient-text">Managing billion pound portfolios with user<span className="letter-space-reset">-</span>centred software.</h1>

          <div>
            <div className="proj__meta">
              <span className="proj__meta-icon material-icons">person</span>
              <span className="proj__meta-text">User researcher,  interaction + visual designer, basic front-end developer</span>
            </div>

            <div className="proj__meta">
              <span className="proj__meta-icon material-icons">groups</span>
              <span className="proj__meta-text">1 UX designer, 1 full stack developer, product owner &#38; project manager</span>
            </div>

            <div className="proj__meta">
              <span className="proj__meta-icon material-icons">watch_later</span>
              <span className="proj__meta-text">4 years</span>
            </div>
          </div>

          <div className="proj__intro">
            <div>
              <p>Four years ago, an investment management firm decided they needed a bespoke web application to help them manage their growing company. They identified one potential blocker to their growth plans was how over reliant the fund managers and analysts were on spreadsheets. The users found it difficult to manage all the versions of spreadsheets they had, which could potentially lead to a very costly mistake. In UI terms, they were hampered by the limited level of interaction design they could achieve with a grid of cells and felt the look of a spreadsheet didn’t inspire much confidence when showcasing it to their investors. A bespoke web app, they hoped, would resolve all these issues.</p>
            </div>

            <img className="proj__hero-pic" src={HeroPic} alt="Laptop with application running on it"></img>
          </div>

          <div className="proj__strategy">
            <h3 className="proj__heading-3 sea-green-text">Strategy</h3>
            <p>I have worked as the UX lead through 50+ iterations to help build out the client’s now very sophisticated application. Below are the steps that we generally take when creating a new feature:</p>
            <ul className="bullet-list proj__bullet-list">
              <li><span className="bullet-list__text">BA gathers and briefs me on new requirements, often including Excel wireframe created by user;</span></li>
              <li><span className="bullet-list__text">I form an understanding, ask BA or users questions to validate assumptions;</span></li>
              <li><span className="bullet-list__text">I create wireframes containing interaction and visual designs;</span></li>
              <li>
                <span className="bullet-list__text">Showcase wireframes, gather &#38; implement feedback from users &#38; BA;
                  <div className="iterate-badge">
                    <span className="material-icons iterate-badge__icon">replay</span>
                    <span className="iterate-badge__text">iterate</span>
                  </div>
                </span>
              </li>
              <li><span className="bullet-list__text">Build markup and SCSS;</span></li>
              <li><span className="bullet-list__text">Work with developers to integrate and bring markup to life;</span></li>
              <li>
                <span className="bullet-list__text">Showcase production version, gather &#38; implement feedback from users.
                <div className="iterate-badge">
                    <span className="material-icons iterate-badge__icon">replay</span>
                    <span className="iterate-badge__text">iterate</span>
                  </div>
                </span>
                </li>
            </ul>
          </div>

          <div className="proj__solutions">
            <h3 className="proj__heading-3 sea-green-text">Solutions</h3>
            <p>Over the four years, we have had to understand and solve many problems, which I will be unable to completely detail here. Instead, I have selected what I think are two of the most interesting features I’ve designed. For additional background, there are only a limited number of users who are all highly proficient with technology, so getting input and feedback from them was very easy. The typical way the app would be used was in an office, on a laptop attached to a large monitor.</p>
            <div className="table-card__overflow-container--1">
              <div className="table-card__overflow-container--2">
                <div className="table-card__container">
                  <table className="table-card">
                    <thead>
                      <tr>
                        <th className="table-card__heading"><h4 className="sea-green-text">Problem</h4></th>
                        <th className="table-card__heading"><h4 className="sea-green-text">Solution</h4></th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td className="table-card__data table-card__numbered-cell">
                          <h5 className="sea-green-text">1.</h5>
                          <p className="table-card__text">Managing large number of <strong>events</strong> hosted by invested companies was challenging</p>
                        </td>
                        <td className="table-card__data">
                          <p className="table-card__text">Design series of interactions to speed up import, and allocation of events in responsive web app</p>
                        </td>
                      </tr>

                      <tr>
                        <td className="table-card__data table-card__numbered-cell">
                          <h5 className="sea-green-text">2.</h5>
                          <p className="table-card__text">Fund manager use <strong>modelling</strong> spreadsheet to understand how changes affect fund</p>
                        </td>
                        <td className="table-card__data">
                          <p className="table-card__text">Understand existing process to create powerful &#38; compact modelling function in web app</p></td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>

        <img className="jagged-border--top" src={Border} alt="border"></img>
        <div className="jagged-border__inner-container">
          <div className="page-contents proj__problem">
            <h2 className="proj__problem-heading gradient-text">Problem 1.</h2>
            <h4 className="proj__problem-sub-heading sea-green-text">Managing events</h4>
            <p>Companies have events to update investors on the current and future state of the company. When you manage multiple funds with many investments, keeping track of what events there are and who is covering them gets tricky. Prior to the project, this was managed by an analyst using a spreadsheet. One of their main issues was that too much time was spent confirming and allocating analysts to events. Also problematic was that any new events had to be manually imported from a web UI.</p>
            <p>My first design solved the importing events problem very well. I designed a number of interactions with the specific intention of speeding up the selection process:</p>
            <ul className="bullet-list proj__bullet-list">
              <li><span className="bullet-list__text">Fast date range selection was handled by the dropdown picker (pictured below);</span></li>
              <li><span className="bullet-list__text">Whole rows were selectable meaning there was no need for precise mouse control;</span></li>
              <li><span className="bullet-list__text">Events that met certain criteria defined by the client were pre-selected for import;</span></li>
              <li><span className="bullet-list__text">Special events were highlighted with badges, making it quick to pick them out.</span></li>
            </ul>
            <div className="proj__gallery">
              <Gallery1 />
            </div>
            <p>Designing the ‘allocate analysts’ solution proved more challenging. My intention with the first design was for each input box to be an auto-complete selection tool, with each analyst typed in becoming a pill that could easily be removed. This design failed though as it did not recognise that the user would be allocating events in bulk. With input from the user and a better understanding of their needs, I created the second iteration. Using the radio buttons on each row, the user could allocate one analyst per event, understanding from the top row initials which analyst they were allocating to and how many events they had so far given them.</p>
            <div className="proj__gallery">
            <Gallery2 />
            </div>
            <p>In the second iteration design, the green radio buttons indicated that a user was interested in being allocated to that event. To help the analysts/fund managers mark their interest, I added a star button shown below next to each event. A user quickly scans through the events and simply hits the star on the ones they’re interested in. I worked with the tech lead to ensure the interaction was as seamless as possible, with him using AJAX to prevent the page from reloading.</p>
            <div className="proj__gallery">
              <Gallery3 />
            </div>
          </div>
        </div>
        <img className="jagged-border--bottom" src={Border} alt="border"></img>

        <div className="page-contents proj__problem">
          <h2 className="proj__problem-heading gradient-text">Problem 2.</h2>
          <h4 className="proj__problem-sub-heading sea-green-text">Modelling</h4>
          <p>Fund managers regularly analyse their portfolios and consider changes they should make to increase a fund’s performance. To help with this, they used spreadsheets in which they’d enter information like companies currently in the fund, and how much cash it held. The inherent issues with spreadsheets remained though. It was difficult to version manage, and the interaction design was limited. I was therefore tasked with creating a modelling tool inside the application.</p>
          <p>To design the various interactions of the page, first I had to understand how the fund managers used their existing tool. To help, I was provided the original spreadsheet along with a mockup wireframe produced in Excel to give me an idea of how they pictured it. With these, I was able to ask further clarifying questions, after which I could produce the first iteration.</p>
          <div className="proj__gallery">
              <Gallery4 />
          </div>
          <p>One key design request for the new modelling feature was that all the information and controls were contained within a single viewport, i.e. no scrolling. An interaction that would make this challenging was how new companies were added to the modelling scenario. For each company, the fund managers also wanted to perform various actions like adding a comment or changing the ‘position’. The design I created compacted the name of the company and its actions all into a single row. For the action buttons, I created a double use for them as they also indicated the status related to that action.</p>
          <p>Colour blindness was also considered in the chart visualisations as one of the fund managers had difficulty distinguishing between red and green. To accommodate this, I searched our charting library for a bar chart that would allow us to not solely rely on colour to indicate if a company was breaching a threshold they had defined. I discovered what we called the ‘hotdog chart’, which met our needs exactly. The inner bar was used to indicate the actual value and the outer bar was the threshold. For more precise information, a hover over was added for each bar.</p>
          <div className="proj__gallery">
              <Gallery5 />
          </div>
        </div>

        <img className="jagged-border--top" src={Border} alt="border"></img>
        <div className="jagged-border__inner-container jagged-border__inner-container--bottomless">
        <div className="page-contents proj__problem">
          <h2 className="proj__problem-heading proj__conclusion gradient-text">Conclusion</h2>
          <p>During the four years of working on this project, I have learned so much about product design. From learning to take a holistic view of visual design or adding new features, to designing complex interactions used by highly technical users. It’s been a very successful project, and the client and users are thrilled with the results. We’re regularly reminded by them how integral to their business the application is, how impressed their investors are when it’s showcased to them and how excited they are to continue developing it into an even more comprehensive application.</p>
        </div>        
        <div className="more-projects">
          <div className="page-contents">
            <h3 className="more-projects__heading gradient-text">More projects</h3>
          </div>
          <div className="project-card__horizontal-scroll-container">
            <div className="project-card__horizontal-scroll">
              <ProjPharmaCard />
              <ProjPaymentsCard />
            </div>
          </div>
        </div>
        </div>
        <BackToTop />
      </div>
    )
  }
}

export default ProjFund;