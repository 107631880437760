import React, { Component } from 'react';
import './Jobs.scss';

export class Jobs extends Component {

    render() {
        return (
            <div className="jobs__main">
                <div className="jobs__item">
                    <span className="jobs__icon material-icons">work</span>
                    <div className="jobs__detail">
                        <span className="jobs__title">UX Consultant</span>
                        <span className="jobs__company">Black Pepper Software Ltd</span>
                        <span className="jobs__date">August 2017 - Present</span>
                        <p className="jobs__description">
                        Over a period of four years, I successfully delivered 15 projects, acting as the UX lead on eight. Working in a consultancy exposed me to a wide variety of projects in very challenging sectors such as fintech and pharma. I have kept users central to all these projects by being their champion, doing as much as possible to involve them through activities like interviews and testing. This focus has led to many successes, such as apps that process over 900 million pounds of business in the first two weeks of usage; an app on which I led the research, design and markup development. 
                        </p>
                        <p className="jobs__description">To further improve my skills, I’ve completed multiple certifications including Scrum with UX and becoming an NN/g UX Master. Helped by these certifications, I have the confidence to lead in discussions about UX strategy and Agile project management.</p>
                    </div>
                </div>
                <div className="jobs__item">
                    <span className="jobs__icon material-icons">work</span>
                    <div className="jobs__detail">
                        <span className="jobs__title">Intern Software Developer</span>
                        <span className="jobs__company">IBM</span>
                        <span className="jobs__date">July 2015 - July 2016</span>
                        <p className="jobs__description">In the API Connect technical foundation team, I had a large focus on Jenkins CI enhancements where I created features used by the whole development team. Role successes included discovering several critical defects through my effective load testing and introducing scheduling logic that reduced wasted processing by 50%. Independent working was a skill used throughout, always making sure that a client’s problems were fully understood, then delivering a solution that met their needs.</p>
                    </div>
                </div>
                <div className="jobs__item">
                    <span className="jobs__icon material-icons">work</span>
                    <div className="jobs__detail">
                        <span className="jobs__title">Software Developer</span>
                        <span className="jobs__company">Aston Active Software Engineering</span>
                        <span className="jobs__date">February 2014 - April 2014</span>
                        <p className="jobs__description">An accelerated internship in which I led the development of an Android application for a client, giving me a first experience of the collaborative Agile process. We went through the entire product lifecycle, from spec and research through to dev and deployment onto the Google Play store. As product owner, I was able to work on my communication and team working skills, along with my ability to understand requirements and write Java code.</p>
                    </div>
                </div>
            </div>
        )
    }
}

export default Jobs;