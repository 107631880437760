
import React from "react";
import './App.scss';
import NavBar from '../NavBar/NavBar'
import NavBarMobile from '../NavBarMobile/NavBarMobile'
import Home from '../Home/Home'
import Footer from '../Footer/Footer'
import ProjPharma from '../Projects/ProjPharma/ProjPharma'
import ProjFund from '../Projects/ProjFund/ProjFund'
import ProjPayments from '../Projects/ProjPayments/ProjPayments'
import Timezones from "../Timezones/Timezones";
import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";

export default function App() {
  return (
    <Router>
      <div className="page-container">

        {/* A <Switch> looks through its children <Route>s and
            renders the first one that matches the current URL. */}
        <Switch>
          <Route path="/projects/pharmaceutical-research">
            <div className="nav-bar--desktop"><NavBar/></div>
            <div className="nav-bar--mobile"><NavBarMobile/></div>
            <ProjPharma />
            <Footer />
          </Route>
          <Route path="/projects/fund-management">
            <div className="nav-bar--desktop"><NavBar/></div>
            <div className="nav-bar--mobile"><NavBarMobile/></div>
            <ProjFund />
            <Footer />
          </Route>
          <Route path="/projects/payments-research">
            <div className="nav-bar--desktop"><NavBar/></div>
            <div className="nav-bar--mobile"><NavBarMobile/></div>
            <ProjPayments />
            <Footer />
          </Route>
          <Route path="/timezones">
            <Timezones />
          </Route>
          <Route path="/">
            <div className="nav-bar--desktop"><NavBar/></div>
            <div className="nav-bar--mobile"><NavBarMobile/></div>
            <Home />
            <Footer />
          </Route>
        </Switch>
      </div>
    </Router>
  );
}