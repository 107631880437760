import React, { Component } from 'react';
import '../../ProjGallery.scss';
import 'photoswipe/dist/photoswipe.css'
import { Gallery, Item } from 'react-photoswipe-gallery'
import PharmaZero_Home_Thumbnail from '../../../../assets/projects/pharma/pharma-zero__home--thumbnail.jpg'
import PharmaZero_Home from '../../../../assets/projects/pharma/pharma-zero__home.jpg'
import PharmaZero_Compound_Thumbnail from '../../../../assets/projects/pharma/pharma-zero__compound--thumbnail.jpg'
import PharmaZero_Compound from '../../../../assets/projects/pharma/pharma-zero__compound.jpg'
import PharmaZero_Trials_Thumbnail from '../../../../assets/projects/pharma/pharma-zero__clinical-trials--thumbnail.jpg'
import PharmaZero_Trials from '../../../../assets/projects/pharma/pharma-zero__clinical-trials.jpg'
import PharmaFirst_Home_Thumbnail from '../../../../assets/projects/pharma/pharma-first__home--thumbnail-xs.jpg'
import PharmaFirst_Home from '../../../../assets/projects/pharma/pharma-first__home.jpg'
import PharmaFirst_Compound_Thumbnail from '../../../../assets/projects/pharma/pharma-first__compound--thumbnail.jpg'
import PharmaFirst_Compound from '../../../../assets/projects/pharma/pharma-first__compound.jpg'
import PharmaFirst_Trials_Thumbnail from '../../../../assets/projects/pharma/pharma-first__clinical-trials--thumbnail.jpg'
import PharmaFirst_Trials from '../../../../assets/projects/pharma/pharma-first__clinical-trials.jpg'
import PharmaThird_Homepage_Thumbnail from '../../../../assets/projects/pharma/pharma-third__homepage--thumbnail-xs.jpg'
import PharmaThird_Homepage from '../../../../assets/projects/pharma/pharma-third__homepage.jpg'
import PharmaSecond_Compound_Thumbnail from '../../../../assets/projects/pharma/pharma-second__compound--thumbnail.jpg'
import PharmaSecond_Compound from '../../../../assets/projects/pharma/pharma-second__compound.jpg'
import PharmaSecond_Trials_Thumbnail from '../../../../assets/projects/pharma/pharma-second__clinical-trials--thumbnail.jpg'
import PharmaSecond_Trials from '../../../../assets/projects/pharma/pharma-second__clinical-trials.jpg'

export class PharmaGallery extends Component {

    render() {
        return (
            <Gallery shareButton={false}>
                <div className="proj-gallery__container">
                <table className="proj-gallery">
                    <thead>
                        <tr>
                            <th className="proj-gallery__sticky"></th>
                            <th className="proj-gallery__heading-top"><h5>Before project</h5></th>
                            <th className="proj-gallery__heading-top"><h5>First iteration</h5></th>
                            <th className="proj-gallery__heading-top"><h5>Second iteration</h5></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <th className="proj-gallery__sticky"><div className="proj-gallery__heading-left"><h5>Homepage</h5></div></th>
                            <td>
                                <Item
                                    original={PharmaZero_Home}
                                    thumbnail={PharmaZero_Home_Thumbnail}
                                    width="1400"
                                    height="800"
                                    title="Homepage before project began"
                                >
                                    {({ ref, open }) => (
                                        <div className="proj-gallery__thumbnail">
                                            <img className="proj__gallery--3-thumbnail" ref={ref} onClick={open} src={PharmaZero_Home_Thumbnail} alt="Homepage before project began" />
                                        </div>
                                    )}
                                </Item>
                            </td>
                            <td>
                                <Item
                                    original={PharmaFirst_Home}
                                    thumbnail={PharmaFirst_Home_Thumbnail}
                                    width="1400"
                                    height="800"
                                    title="Our first iteration of homepage"
                                >
                                    {({ ref, open }) => (
                                        <div className="proj-gallery__thumbnail">
                                            <img className="proj__gallery--3-thumbnail" ref={ref} onClick={open} src={PharmaFirst_Home_Thumbnail} alt="Our first iteration of homepage" />
                                        </div>
                                    )}
                                </Item>
                            </td>
                            <td>
                                <Item
                                    original={PharmaThird_Homepage}
                                    thumbnail={PharmaThird_Homepage_Thumbnail}
                                    width="1400"
                                    height="800"
                                    title="Our third iteration of homepage with rebranding"
                                >
                                    {({ ref, open }) => (
                                        <div className="proj-gallery__thumbnail">
                                            <img className="proj__gallery--3-thumbnail" ref={ref} onClick={open} src={PharmaThird_Homepage_Thumbnail} alt="Our third iteration of homepage with rebranding" />
                                        </div>
                                    )}
                                </Item>
                            </td>
                        </tr>
                        <tr>
                            <th className="proj-gallery__sticky"><div className="proj-gallery__heading-left"><h5>Drug details</h5></div></th>
                            <td>
                                <Item
                                    original={PharmaZero_Compound}
                                    thumbnail={PharmaZero_Compound_Thumbnail}
                                    width="1400"
                                    height="800"
                                    title="Compound info page before project"
                                >
                                    {({ ref, open }) => (
                                        <div className="proj-gallery__thumbnail">
                                            <img className="proj__gallery--3-thumbnail" ref={ref} onClick={open} src={PharmaZero_Compound_Thumbnail} alt="Clinical trials page before project" />
                                        </div>
                                    )}
                                </Item>
                            </td>
                            <td>
                                <Item
                                    original={PharmaFirst_Compound}
                                    thumbnail={PharmaFirst_Compound_Thumbnail}
                                    width="1400"
                                    height="800"
                                    title="Our first iteration of compound info page"
                                >
                                    {({ ref, open }) => (
                                        <div className="proj-gallery__thumbnail">
                                            <img className="proj__gallery--3-thumbnail" ref={ref} onClick={open} src={PharmaFirst_Compound_Thumbnail} alt="Our first iteration of compound info page" />
                                        </div>
                                    )}
                                </Item>
                            </td>
                            <td>
                                <Item
                                    original={PharmaSecond_Compound}
                                    thumbnail={PharmaSecond_Compound_Thumbnail}
                                    width="1400"
                                    height="800"
                                    title="Our second iteration of compound info page"
                                >
                                    {({ ref, open }) => (
                                        <div className="proj-gallery__thumbnail">
                                            <img className="proj__gallery--3-thumbnail" ref={ref} onClick={open} src={PharmaSecond_Compound_Thumbnail} alt="Our second iteration of compound info page" />
                                        </div>
                                    )}
                                </Item>
                            </td>
                        </tr>
                        <tr>
                            <th className="proj-gallery__sticky"><div className="proj-gallery__heading-left"><h5>Trials overview</h5></div></th>
                            <td>
                                <Item
                                    original={PharmaZero_Trials}
                                    thumbnail={PharmaZero_Trials_Thumbnail}
                                    width="1400"
                                    height="800"
                                    title="Clinical trials page before project"
                                >
                                    {({ ref, open }) => (
                                        <div className="proj-gallery__thumbnail">
                                            <img className="proj__gallery--3-thumbnail" ref={ref} onClick={open} src={PharmaZero_Trials_Thumbnail} alt="First iteration of the compound page with links to clinical trials" />
                                        </div>
                                    )}
                                </Item>
                            </td>
                            <td>
                                <Item
                                    original={PharmaFirst_Trials}
                                    thumbnail={PharmaFirst_Trials_Thumbnail}
                                    width="1400"
                                    height="800"
                                    title="Our first iteration of clinical trials page"
                                >
                                    {({ ref, open }) => (
                                        <div className="proj-gallery__thumbnail">
                                            <img className="proj__gallery--3-thumbnail" ref={ref} onClick={open} src={PharmaFirst_Trials_Thumbnail} alt="Our first iteration of clinical trials page" />
                                        </div>
                                    )}
                                </Item>
                            </td>
                            <td>
                                <Item
                                    original={PharmaSecond_Trials}
                                    thumbnail={PharmaSecond_Trials_Thumbnail}
                                    width="1400"
                                    height="800"
                                    title="Our second iteration of clinical trials page"
                                >
                                    {({ ref, open }) => (
                                        <div className="proj-gallery__thumbnail">
                                            <img className="proj__gallery--3-thumbnail" ref={ref} onClick={open} src={PharmaSecond_Trials_Thumbnail} alt="Our second iteration of clinical trials page" />
                                        </div>
                                    )}
                                </Item>
                            </td>
                        </tr>
                        <tr>
                            <td className="proj-gallery__sticky proj-gallery__padding-row"></td>
                            <td></td>
                            <td></td>
                            <td></td>
                        </tr>
                    </tbody>
                </table>
                </div>
            </Gallery>
        )
    }
}

export default PharmaGallery;