import React, { Component } from 'react';
import PharmaThumbnail from '../../../assets/projects/pharma_thumbnail.jpg'

export class ProjFundCard extends Component {

  render() {
    return (
      <a href="/projects/pharmaceutical-research" className="project-card">
        <div className="project-card__content">
          <h3 className="project-card__heading">Selling the latest pharmaceutical research to medics.
            <span className="project-card__icon material-icons icon__arrow_forward"></span>
          </h3>
          <span className="tag-text project-card__tags-desktop">RESEARCH • INTERACTION DESIGN • VISUAL DESIGN • DEVELOPMENT</span>
        </div>
        <img className="project-card__thumbnail" src={PharmaThumbnail} alt="Pharmaceutical research showcase app on tablet"></img>
        <span className="tag-text project-card__tags-mobile">RESEARCH • INTERACTION DESIGN • VISUAL DESIGN • DEVELOPMENT</span>
      </a>
    )
  }
}

export default ProjFundCard;