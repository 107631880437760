import React, { Component } from 'react';
import FundThumbnail from '../../../assets/projects/fund_thumbnail.jpg'

export class ProjFundCard extends Component {

  render() {
    return (
      <a href="/projects/fund-management" className="project-card">
          <div className="project-card__content">
              <h3 className="project-card__heading proj-fund__card-title">Managing billion pound portfolios with user<span className="letter-space-reset">-</span>centred software.
                  <span className="project-card__icon material-icons icon__arrow_forward"></span>
              </h3>
              <span className="tag-text project-card__tags-desktop">RESEARCH • INTERACTION DESIGN • VISUAL DESIGN • DEVELOPMENT</span>
          </div>
          <img className="project-card__thumbnail" src={FundThumbnail} alt="Fund managment app on laptop"></img>
          <span className="tag-text project-card__tags-mobile">RESEARCH • INTERACTION DESIGN • VISUAL DESIGN • DEVELOPMENT</span>
      </a>
    )
  }
}

export default ProjFundCard;